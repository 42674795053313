<template>
  <form
    class="form"
    data-e2e="reset-password-form"
  >
    <div v-if="$route.query.reset_password_token">
      <div class="my-3">
        <IInput
          :id="'password'"
          v-model.trim="password"
          :label="'Password'"
          :input-type="'password'"
          :placeholder="'Enter your secret password'"
          :help-text="`
            Your new password... <br>
                   <span class=${
            containOneDigit && 'password_pattern_validation'
          }> 1. must contain digit [0-9]
                   ${
            password
              ? containOneDigit
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br /></span>
        <span
        class=${oneLowercaseCharacter && 'password_pattern_validation'}
          >2. One lowercase character [a-z] ${
            password
              ? oneLowercaseCharacter
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br
        /></span>
        <span
        class=${oneUppercaseCharacter && 'password_pattern_validation'}
          >3. One uppercase character [A-Z] ${
            password
              ? oneUppercaseCharacter
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br
        /></span>
        <span class=${
            oneSymbol && 'password_pattern_validation'
          }>4. One symbol [@#$%] ${
            password
              ? oneSymbol
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br /></span>
        <span
        class=${lengthISGreaterThen && 'password_pattern_validation'}
          >5. Length of password between [5-20].${
            password
              ? lengthISGreaterThen
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }</span
        >
        `"
          :tab-index="5"
          :klass="[
            'password',
            getError && getError.input_name == 'password' && getError.status,
          ]"
          :data-e2e="'password-input'"
        />
      </div>
      <div
        v-if="showPasswordConfirmation"
        class="my-3 input-group"
      >
        <IInput
          :id="'password_confirmation'"
          v-model.trim="password_confirmation"
          :label="'password_confirmation'"
          :input-type="'password'"
          :placeholder="'Enter password confirmation'"
          :tab-index="6"
          :klass="[
            'password_confirmation',
            getError &&
              getError.input_name == 'password_confirmation' &&
              getError.status,
          ]"
          :data-e2e="'password-confirmation-input'"
          :is-required="true"
          @focusout="verifyPasswordConfirmation"
        />
      </div>
    </div>
    <div v-else>
      <div class="my-3">
        <IInput
          :id="'email'"
          v-model="email"
          :label="'Email'"
          :input-type="'email'"
          :placeholder="'Your email'"
          :tab-index="1"
          :klass="[
            getError && getError.input_name === 'email' && getError.status,
          ]"
          :data-e2e="'email-input'"
        />
      </div>
    </div>
    <div class="mt-4 mb-5">
      <IButton
        :action="'success'"
        :tab-index="3"
        class="btn btn-primary my-4"
        :data-e2e="'continue-button'"
        @click.prevent="
          resetPasword(
            $route.query.reset_password_token ? 'updatePassword' : 'sendEmail'
          )
        "
      >
        Continue
      </IButton>
    </div>
  </form>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

  import { Button as IButton, Input as IInput } from '@/components'

  import {
  validateEmail,
  validatePassword,
  validatePasswordConfirmation,
} from '@/helpers'
import { routesNames } from '@/router'
import { useAccountStore } from '@/store/accounts'
import { useErrorStore } from '@/store/errorStore'
import { useToastStore } from '@/store/toastStore'
import type { AccountPasswordRequest } from '@/types'
import { debounceFilter, watchWithFilter } from '@vueuse/core'
import { mapState, mapStores } from 'pinia'
import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'ResetPasswordForm',
    components: {
      IInput,
      IButton,
    },
    setup: () => {
      // Define needed instances.
      const router = useRouter()
      const route = useRoute()
      // Define needed variables.
      const email = ref('')
      const password = ref('')
      const password_confirmation = ref('')
      const showPasswordConfirmation = ref(false)
      const canSubmitted = ref(false)
      const containOneDigit = ref(false)
      const oneLowercaseCharacter = ref(false)
      const oneUppercaseCharacter = ref(false)
      const oneSymbol = ref(false)
      const lengthISGreaterThen = ref(false)
      const accountStore = useAccountStore()

      watchWithFilter(
        email,
        () => {
          if (validateEmail(email.value)) {
            useErrorStore().$reset()
            canSubmitted.value = true
          } else {
            if (email.value.length <= 8) return
            useErrorStore().UPDATE_ERROR({
              message: 'Invalid Email',
              status: 'error',
              input_name: 'email',
              reset: true,
            })
          }
        },
        {
          eventFilter: debounceFilter(500, { maxWait: 10000 }),
        }
      )

      // Define async function for user login with email and password.
      const resetPasword = async (action: string) => {
        // Define data from components.
        const requestData: AccountPasswordRequest = {
          reset_password_token: route.query.reset_password_token as string,
          e2e_bypass_token: route.query.e2e_bypass_token as string,
          password: password.value,
          password_confirmation: password_confirmation.value,
        }

        if (action === 'sendEmail' && canSubmitted.value) {
          accountStore.SEND_USER_EMAIL_RESET_PASSWORD({
            email: email.value,
            e2e_bypass_token: route.query.e2e_bypass_token as string,
          })

          accountStore.$subscribe(
            (
              _mutation: any,
              state: { status: number; message: any }
            ) => {
              if (state.status === 200 && state.message) {
                useToastStore().SHOW_NOTIFICATION(state.message, 'success', true, false)
                useAccountStore().$patch(
                  (state: {
                    status: number
                    message: string
                  }) => {
                    state.status = 0

                    state.message = ''
                  }
                )
                router.push({ name: routesNames.home })
              }
            }
          )
        } else if (canSubmitted.value) {
          accountStore.UPDATE_PASSWORD({
            account_data: requestData,
            current_user_email: undefined,
          })

          accountStore.$subscribe(
            (
              _mutation: any,
              state: { status: number; message: string }
            ) => {
              if (
                state.status === 200 &&
                state.message === 'Password changed successfully'
              ) {
                useToastStore().SHOW_NOTIFICATION(state.message, 'success', true, false)
                useAccountStore().$patch(
                  (state: {
                    status: number

                    message: string
                  }) => {
                    state.status = 0
                    state.message = ''
                  }
                )
                router.push({ name: routesNames.login })
              }
            }
          )
        }
      }

      // Return instances and variables.
      return {
        email,
        password,
        resetPasword,
        password_confirmation,
        showPasswordConfirmation,
        canSubmitted,
        containOneDigit,
        oneLowercaseCharacter,
        oneUppercaseCharacter,
        oneSymbol,
        lengthISGreaterThen,
      }
    },
    computed: {
      ...mapState(useErrorStore, {
        getError: 'errors',
      }),
      ...mapStores(useErrorStore),
    },
    watch: {
      password(value) {
        const [
          showPasswordConfirmation,
          canSubmitted,
          containOneDigit,
          oneLowercaseCharacter,
          oneUppercaseCharacter,
          oneSymbol,
          lengthISGreaterThen,
        ] = validatePassword(value)
        this.showPasswordConfirmation = showPasswordConfirmation
        this.canSubmitted = canSubmitted
        this.containOneDigit = containOneDigit
        this.oneLowercaseCharacter = oneLowercaseCharacter
        this.oneUppercaseCharacter = oneUppercaseCharacter
        this.oneSymbol = oneSymbol
        this.lengthISGreaterThen = lengthISGreaterThen
      },
    },
    methods: {
      verifyPasswordConfirmation() {
        const [canSubmitted] = validatePasswordConfirmation(
          this.password,
          this.password_confirmation
        )
        this.canSubmitted = canSubmitted
      },
    },
  })
</script>
