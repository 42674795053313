<template>
  <div
    class="input-group"
  >
    <label
      :for="id"
    >
      {{ label }}{{ isRequired ? '*' : '' }}
    </label>
    <div class="input-group">
      <input
        v-if="showPassword && inputType === 'password'"
        :id="id"
        v-model="InputModel"

        :class="['form-field', klass]"
        type="text"
        :tabindex="tabIndex"
        :placeholder="isRequired ? `${placeholder}*` : placeholder"
        :required="isRequired"
        :data-e2e="dataE2e"
        @keydown.enter="highlightRequiredFields"
      >
      <input
        v-else
        :id="id"
        v-model="InputModel"
        :class="['form-field', klass]"
        :type="inputType"
        :tabindex="tabIndex"
        :placeholder="isRequired ? `${placeholder}*` : placeholder"
        :required="isRequired"
        :readonly="isReadonly"
        :name="id"
        :autocomplete="autocomplete"
        :data-e2e="dataE2e"
        @keydown.enter="highlightRequiredFields"
      >
      <div
        v-if="inputType === 'password'"
        class="input-icon-container"
        data-e2e="show-password"
        @click="toggleShow"
      >
        <EyeIcon v-if="showPassword" />
        <EyeOffIcon v-else />
      </div>
    </div>
    <small>
      <p
        v-if="helpText"
        class="mt-2 mx-2 align-left font-black"
        v-html="helpText"
      />
    </small>
  </div>
</template>

<script lang="ts">
  import { toTitleCase } from '@/helpers';
import { useToastStore } from '@/store/toastStore';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/outline';
import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IInput',
    components: {
      EyeIcon,
      EyeOffIcon,
    },

    props: {
      id: { type: String, required: true },
      label: { type: String, required: true },
      klass: { type: Array, default: () => [''] },
      inputType: { type: String, required: true },
      tabIndex: { type: Number, required: true },
      helpText: { type: String, default: '' },
      name: { type: String, default: '' },
      autocomplete: { type: String, default: '' },
      placeholder: { type: String, default: '' },
      modelValue: { type: [String, Boolean, Number], default: '' },
      isRequired: { type: Boolean, default: false },
      isReadonly: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      dataE2e: {
        type: String,
        default: '',
        required: false,
      },
      // showPassword: { type: Boolean, default: false },
    },
    emits: ['update:modelValue'],
    setup: () => {
      // Define event handler for input change.
      const handleInputChange = (event: Event) =>
        (event.target as HTMLInputElement).value
      return { handleInputChange }
    },
    data() {
      return {
        showPassword: false,
      }
    },
    computed: {
      buttonLabel() {
        return this.showPassword ? 'Hide' : 'Show'
      },

      InputModel: {
        get() {
          return this.modelValue
        },
        set(newChecked: unknown) {
          this.$emit('update:modelValue', newChecked)
        },
      },
    },
    methods: {
      toggleShow() {
        this.showPassword = !this.showPassword
      },
      highlightRequiredFields(event: Event) {
        const input = event.target as HTMLInputElement
      if (!input.value && this.isRequired) {
        input.classList.add('error')
        input.setCustomValidity(`${toTitleCase(input.name)} cannot be empty`)
        useToastStore().SHOW_NOTIFICATION(`${toTitleCase(input.name)} cannot be empty`, 'error', true, false)
      } else {
        input.classList.remove('error')
        input.setCustomValidity('')
      }
    }
    },
  })
</script>

<style lang="sass" scoped></style>
