<template>
  <div>
    <img
      src="/src/assets/btn-back.png"
      srcset="/src/assets/btn-back@2x.png 2x"
      alt="Previous Page"
      @click="goBack"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'GoBack',
    methods: {
      goBack () {
        return this.$router.go(-1)
      },
    },
  })
</script>

<style scoped></style>
