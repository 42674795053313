import { AdminDataService as Admin } from '@/services/index'
import type { ApiResponse, PortfoliosType } from '@/types/index'
import { defineStore } from 'pinia'
import { ActionTypes } from '../action-types'

import ErrorMessage from '@/ErrorMessage'
import { state as initialState } from '@/store/state'
import Bugsnag from '@bugsnag/js'
import { pick } from 'lodash'
import { useErrorStore } from '../errorStore'
const state = pick(initialState, [
  'subscribers',
  'isRequestLoading',
  'isLoading',
  'status',
  'statusText',
  'message',
  'portfolios',
  'portfolios_sessions',
  'current_user',
])

export const useAdminStore = defineStore('adminStore', {
  state: () => ({ ...state }),
  getters: {
    currentAccountPortfolios({ portfolios }): PortfoliosType {
      return portfolios
    },
  },

  actions: {
    async [ActionTypes.GET_SUBSCRIBERS]() {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const admin = Admin.getSubscribers()

        resolve(admin)
      })

      try {
        const { data, status, statusText } = await promise

        this.subscribers = data.data
        this.status = Number(status)
        this.statusText = statusText
        this.message = 'Email has been invited'
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.GET_SUBSCRIBERS, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
          this.isLoading = false;
        }
      }
    },
    async [ActionTypes.DELETE_PORTFOLIO]({ style_id }: { style_id: string }) {
      this.isRequestLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolio = Admin.deletePortfolio(style_id)

        resolve(portfolio)
      })

      try {
        const { data, status, statusText } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.DELETE_PORTFOLIO, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
        }
      }
    },
    async [ActionTypes.DELETE_ACCOUNT]({ account_id }: { account_id: string }) {
      this.isRequestLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const account = Admin.deleteAccount(account_id)

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise
        this.GET_SUBSCRIBERS()
        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.DELETE_ACCOUNT, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
        }
      }
    },
    async [ActionTypes.BLOCK_ACCOUNT]({ account_id }: { account_id: string }) {
      this.isRequestLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const account = Admin.blockAccount(account_id)

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise
        this.GET_SUBSCRIBERS()
        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.BLOCK_ACCOUNT, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
        }
      }
    },
    async [ActionTypes.ALLOW_PORTFOLIO]({ style_id }: { style_id: string }) {
      this.isRequestLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolio = Admin.allowPortfolio(style_id)

        resolve(portfolio)
      })

      try {
        const { data, status, statusText } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.ALLOW_PORTFOLIO, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
        }
      }
    },
    async [ActionTypes.ALLOW_PORTFOLIOS]({
      account_id,
    }: {
      account_id: string
    }) {
      this.isRequestLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolio = Admin.allowPortfolios(account_id)

        resolve(portfolio)
      })

      try {
        const { data, status, statusText } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.ALLOW_PORTFOLIOS, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
        }
      }
    },
    async [ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS]({
      account_id,
    }: {
      account_id: string
    }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolios = Admin.allPortfolioStyles(account_id)

        resolve(portfolios)
      })

      try {
        const {
          data: { data: portfolios },
          status,
          statusText,
        } = await promise
        this.portfolios = portfolios
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
          this.isLoading = false;
        }
      }
    },
    async [ActionTypes.GET_PORTFOLIOS_SESSIONS]() {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolios_sessions = Admin.getPortfoliosSessions()

        resolve(portfolios_sessions)
      })

      try {
        const {
          data: { data: portfolios_sessions },
          status,
          statusText,
        } = await promise
        this.portfolios_sessions = portfolios_sessions
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.';
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.GET_PORTFOLIOS_SESSIONS, error);
            Bugsnag.notify(errorMessage);
          }
          useErrorStore().UPDATE_ERROR(error);
        }
      } finally {
        const errorStore = useErrorStore();
        if (errorStore.errors.status !== 404 && errorStore.errors.status !== 500) {
          this.isRequestLoading = false;
          this.isLoading = false;
        }
      }
    },
  },
  persist: false,
})

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot))
// }
