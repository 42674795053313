import { ActionTypes } from '@/store/action-types'
import { state as initialState } from '@/store/state'
import { defineStore } from 'pinia'
import { useToastStore } from './toastStore'

import { pick } from 'lodash'
const state = pick(initialState, ['errors'])

export const useErrorStore = defineStore('errorStore', {
  state: () => ({ ...state }),
  getters: {
    getError({ errors }) {
      return errors
    }
  },
  actions: {
    [ActionTypes.UPDATE_ERROR](payload: any) {
      const toastStore = useToastStore()
      const errorMessage = payload.response ? payload.response.data : payload
      const message = errorMessage.message || errorMessage.error
      toastStore.SHOW_NOTIFICATION(message, errorMessage.status, true, false)
      this.errors = errorMessage

      // Reset errors after 5 seconds
      setTimeout(() => {
        this.errors = {}
      }, 5000)
    },
    logInfo(message: string, data: any) {
      console.info(message, data)
    }
  },
  persist: false
})
