export interface IRoutesNames {
  home: string
  login: string
  logout: string
  register: string
  stylist: string
  addOrEditStyle: string
  removeStyle: string
  passwordReset: string
  accounts: string
  aboutMe: string
  portfolios: string
  slideShow: string
  styleDetailPage: string
  qrCode: string
  admin: string
  tvLogin: string
  confirmEmail: string
  PageNotFound: string
  imprint: string
  dataPrivacy: string
  cookies: string
  legal: string
  copyright: string
  error_404: string
}

const routesNames: Readonly<IRoutesNames> = {
  home: 'home',
  login: 'login',
  logout: 'logout',
  register: 'register',
  portfolios: 'portfolios',
  stylist: 'stylist',
  addOrEditStyle: 'addOrEditStyle',
  removeStyle: 'removeStyle',
  passwordReset: 'passwordReset',
  accounts: 'accounts',
  aboutMe: 'aboutMe',
  slideShow: 'slideShow',
  styleDetailPage: 'styleDetailPage',
  qrCode: 'qrCode',
  admin: 'admin',
  tvLogin: 'tvLogin',
  confirmEmail: 'confirmEmail',
  PageNotFound: 'Page not found',
  imprint: 'imprint',
  dataPrivacy: 'privacy',
  cookies: 'cookies',
  legal: 'legal',
  copyright: 'copyright',
  error_404: '404',
}

declare module 'vue/types/vue' {
  interface Vue {
    $routesNames: IRoutesNames
  }
}

export default routesNames
