<template>
  <main>
    <h1>Upload Requests</h1>
    <data-table :items="subscribers()" :fields="fields">
      <template #cell(last_signin)="{ value }">
        <b>{{ lastSigning(value) }}</b>
      </template>
      <template #cell(created_at)="{ value }">
        <b>{{ createAt(value) }}</b>
      </template>
      <template #link="{ item }">
        <img
          src="@/assets/btn-approve.png"
          srcset="@/assets/btn-approve@2x.png 2x"
          alt="Approve all"
          @click.prevent="allowPortfolios(item.account_id)"
        />
        &nbsp;
        <img
          src="@/assets/btn-next.png"
          srcset="@/assets/btn-next@2x.png 2x"
          alt="View Profile"
          @click.prevent="goToProfile(item.account_id)"
        />
      </template>
      <template #portfolio-images="{ item }">
        <div v-if="item.portfolio_styles.length > 0" class="portfolio-wrapper">
          <div class="portfolio-entry portfolio-entry--admin">
            <div class="portfolio-gallery-view">
              <ul>
                <li
                  v-for="image of findAndRemoveAndLastRemovedPayload(
                    item.portfolio_styles
                  )"
                  :key="image.id"
                  class="shadow"
                >
                  <c-image
                    v-if="
                      image.filename &&
                      gExtension(image.filename).type === 'img'
                    "
                    :image-url="image.cover"
                    :alt="image.filename"
                    :portfolio-id="image.id?.toString()"
                  />
                  <c-video
                    v-else-if="
                      image.filename &&
                      gExtension(image.filename).type === 'video'
                    "
                    :video-url="image.file"
                    :content-type="gExtension(image.filename).mineType"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </data-table>
  </main>
</template>

<script lang="ts">
  import { CImage, CVideo, DataTable } from '@/components'
import {
  dateShort,
  findAndRemoveAndLastRemovedPayload,
  getExtension,
  weekDayShort,
} from '@/helpers'
import type { AccountType, PortfolioPayloadType } from '@/types'
import {
  Component,
  Emit,
  Inject,
  toNative,
  Vue,
  Watch,
} from 'vue-facing-decorator'

  @Component({
    components: { DataTable, CImage, CVideo },
  })
  class StyleRequests extends Vue {
    @Inject
    currentAccount!: () => AccountType
    @Inject
    subscribers!: () => void

    createAt(date: Date) {
      return dateShort(date)
    }

    lastSigning(date: Date) {
      if (date) {
        return weekDayShort(date)
      }
    }

    @Watch('$route.name', { immediate: true, deep: true })
    updatePageTitle(name: string) {
      if (name === 'admin') {
        document.title = `Admin - Style Requests`
      }
    }

    gExtension(file: string) {
      return getExtension(file)
    }
    findAndRemoveAndLastRemovedPayload(payload: PortfolioPayloadType[]) {
      return findAndRemoveAndLastRemovedPayload(payload)
    }

    @Emit
    goToProfile(account_id: string) {
      return { account_id, gotToTab: 0 }
    }

    @Emit
    allowPortfolios(account_id: string) {
      return { account_id }
    }

    fields = [
      { key: 'account_id', label: 'ID', sortable: true },
      { key: 'first_name', label: 'First name' },
      { key: 'last_name', label: 'Last name' },
      { key: 'subscription', label: 'Subscription', sortable: true },
      { key: 'images', label: 'Images' },
      { key: 'image_requests', label: 'Image request' },
      { key: 'actions', label: '' },
    ]
  }
  export default toNative(StyleRequests)
</script>

<style scoped></style>
