<script setup lang="ts">
import { logoY } from '@/helpers';
import { generateCacheBuster } from '@/utils/cacheBuster';
import { onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps<Props>()
const emit = defineEmits(['videoIsReady', 'videoCannotBeLoaded'])

const isLoading = ref(true)
const isVideoVisible = ref(false)
const isAnErrorValue = ref(false)
const videoContainer = ref<HTMLDivElement | null>(null)
const uniqueVideoSources = ref<VideoSource[]>([])

interface VideoSource {
  src: string
  type: string
}

watch(
  () => props.payload,
  (newPayload) => {
    if (newPayload && newPayload.length > 0) {
      uniqueVideoSources.value = newPayload.map((source) => ({
        src: `${source.file}?cache-buster=${generateCacheBuster()}`,
        type: source.content_type,
      }))
    }
  },
  { immediate: true }
)

function handleImageError(event: Event) {
  isAnErrorValue.value = true
  emit('videoCannotBeLoaded', isAnErrorValue.value)
}

function handleVideoCanPlay() {
  isLoading.value = false
  emit('videoIsReady', true)
  document.documentElement.style.setProperty(
    '--swiper-slide-active-after-display',
    'block'
  )
}

watch(isLoading, (newValue) => {
  if (newValue) {
    document.documentElement.style.setProperty(
      '--swiper-slide-active-after-display',
      'none'
    )
  }
})

let observer: IntersectionObserver | null = null

function observeVideo() {
  if (!videoContainer.value) return

  observer = new IntersectionObserver(
    async ([entry]) => {
      const videoElement = videoContainer.value?.querySelector('video');
      if (entry.intersectionRatio === 1) { // Fully visible
        isVideoVisible.value = true;
        try {
          if (videoElement?.paused) { // Check if the video is paused before playing
            await videoElement.play();
          }
        } catch (error) {
          console.error('Error playing video:', error);
        }
      } else { // Partially or fully hidden
        isVideoVisible.value = false;
        try {
          if (!videoElement?.paused) { // Check if the video is playing before pausing
            await videoElement?.pause();
          }
        } catch (error) {
          console.error('Error pausing video:', error);
        }
      }
    },
    { threshold: 1.0 } // Trigger only when fully visible
  )

  if (videoContainer.value instanceof HTMLElement) {
    observer.observe(videoContainer.value)
  }
}

onMounted(() => {
  if (props.payload && props.payload.length > 0) {
    uniqueVideoSources.value = props.payload.map((source) => ({
      src: `${source.file}?cache-buster=${generateCacheBuster()}`,
      type: source.content_type,
    }))
  }
  observeVideo()
})

onUnmounted(() => {
  if (observer && videoContainer.value instanceof Element) {
    observer.unobserve(videoContainer.value)
  }
  isLoading.value = false
  isVideoVisible.value = false
})

// Expose the error and isAnErrorValue to the parent component for testing purposes
defineExpose({ isLoading, isAnErrorValue, observeVideo })

interface VideoPayload {
  file: string
  content_type: string
  image_key?: string
}

interface Props {
  payload?: VideoPayload[]
  videoUrl?: string
  contentType?: string
  dataE2e?: string
}
</script>

<template>
  <div ref="videoContainer">
    <video
      id="video"
      width="100%"
      autoplay
      muted
      playsinline
      loop
      @canplay="handleVideoCanPlay"
    >
      <template v-for="(source, index) in uniqueVideoSources" :key="index">
        <source
          :src="source.src"
          :type="source.type"
          :data-e2e="dataE2e"
          @error="handleImageError"
        />
      </template>
      <track
        label="English"
        kind="captions"
        srclang="en"
        src=""
        default
      />
    </video>
    <div v-if="isLoading" class="is-ready">
      <img
        :src="logoY"
        :srcset="logoY"
        alt="loader"
        class="loader-logo"
      />
      <div class="is-loader" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .text {
    margin-bottom: 0.7rem;
    font-size: 2rem;
    margin-left: 0.5rem;
  }

  .loader-logo,
  .slideshow .swiper-slide .loader-logo {
    width: unset;
    height: 40%;
  }

  .is-ready {
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background-color: #0000;
    margin: auto;
  }

  .is-loader {
    width: 40px;

    margin-left: -4px;
    margin-bottom: -40%;
    aspect-ratio: 4;
    background: url('/src/assets/logo-dot.svg') 0 / calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 900ms steps(4) infinite;
  }

  @keyframes l1 {
    to {
      clip-path: inset(0 -34% 0 0);
    }
  }
</style>
