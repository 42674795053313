<template>
  <div>
    <div class="profile-gallery">
      <h6 class="mt-5 mb-2 text-left">
        Company Logo*
      </h6>
      <label
        for="scfile"
        title="Upload image"
      >
        <ul class="single-image">
          <li class="shadow">
            <c-image
              :image-url="defaultImage || preview "
              :alt="imageTitle"
              :data-e2e="'avatar-has-been-uploaded'"
            />
          </li>
        </ul>
      </label>
    </div>
    <div
      class="d-flex justify-content-center mt-3 align-items-baseline flex-align-center justify-content-center column-gap-3 mb-5"
    >
      <div>
        <label
          for="scfile"
          class="text-primary fs-7"
          title="Upload image"
        >
          <input
            id="scfile"
            type="file"
            name="scImage"
            autocomplete="off"
            class="hidden"
            data-e2e="avatar-input"
            @change="attachimage"
          >
          <img
            src="@/assets/btn-edit.png"
            srcset="@/assets/btn-edit@2x.png 2x"
            :alt="imageTitle"
          > 
          Upload
        </label>
        <img
          v-if="defaultImage.includes('ypony') && !isLoading()"
          src="/src/assets/btn-delete.png"
          srcset="/src/assets/btn-delete@2x.png 2x"
          alt="Delete avatar"
          class="ml-2"
          @click.prevent="deleteSingle"
        >
      </div>
    </div>
  </div>
</template>

<script>
  import { avatarSizeCheck, getExtension, preProcessImageFile } from '@/helpers';
import { useErrorStore } from '@/store/errorStore';
import { useAccountStore } from '../../../store/accounts/acountStore';
import CImage from '../../CImage.vue';

  export default {
    name: 'CreateSingleUpload',
    components: { CImage },

    props: {
      defaultImage: {
        type: String,
        default: '',
      },
      imageTitle: {
        type: String,
        default: 'Choose image',
      },
    },
    emits: ['createSingle', 'deleteSingle', 'isConverting'],
    data () {
      return {
        preview: '',
        del: false,
      }
    },
    methods: {
      isLoading () {
        return useAccountStore().isLoading
      },
      async attachimage (e) {
        e.preventDefault()
        const file = e.target.files[0]

        if (avatarSizeCheck(file) === false) return

        if (getExtension(file).type === 'video') {
          useErrorStore().UPDATE_ERROR({
            message: 'Avatar cannot be a video',
            status: 'warning',
            reset: true,
          })
          return
        }

        const imageURL = await preProcessImageFile(file, this.$emit)

        this.preview = imageURL.preview || URL.createObjectURL(file)

        this.$emit('createSingle', imageURL.fileToUpload)
      },
      deleteSingle () {
        this.$emit('deleteSingle')
        this.preview = ''
      },
    },
  }
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>
