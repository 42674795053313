import { type Consumer, createConsumer } from "@rails/actioncable";
import { defineStore } from 'pinia';
import { useRoute } from "vue-router";
import { getIDFromString } from "../helpers";
import { routesNames } from "../router";
import { useAuthTokenStore } from "./authToken";

export const useCableStore = defineStore('cable', {
  state: () => ({
    consumer: null as Consumer | null,

  }),
  actions: {
    initializeConsumer() {
      const host = import.meta.env.VITE_VUE_APP_YPONY_ACTIONCABLE_URL ?? window.location.host;
      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const route = useRoute();
      const authTokenStore = useAuthTokenStore()

      let accountId = route.name === routesNames.slideShow ? getIDFromString(
        route.params.fullname_account_id as string
      ) : authTokenStore.current_user.account_id;
      let queryParams = '';

      queryParams = accountId ? `account_id=${accountId}&route_name=${String(route.name).toLowerCase()}` : 'auth_token=auth_token';

      const websocketUrl = `${protocol}://${host}/cable?${queryParams}`;

      this.consumer = createConsumer(websocketUrl);
    },
    disconnectConsumer() {
      if (this.consumer) {
        this.consumer.disconnect();
        this.consumer = null;
      }
    }
  }
});
