<template>
  <main class="text-left">
    <TabGroup
      :selected-index="selectedTab"
      @change="changeTab"
    >
      <TabList class="tab-nav d-flex my-5 grid-gap-4">
        <Tab
          v-for="tab in tabHeadlines"
          :key="tab"
          v-slot="{ selected }"
          as="template"
        >
          <h5 :class="['my-0', selected ? 'active' : '']">
            {{ tab }}
          </h5>
        </Tab>
      </TabList>
      <TabPanels class="mt-5">
        <TabPanel>
          <subscribers
            @go-to-profile="goToProfile"
            @update-selected-sub-tab="updateSelectedSubTab"
          />
        </TabPanel>
        <TabPanel>
          <style-requests
            @go-to-profile="goToProfile"
            @allow-portfolios="allowPortfolios"
          />
        </TabPanel>
        <TabPanel>
          <analytics
            @go-to-profile="goToProfile"
            @update-selected-sub-tab="updateSelectedSubTab"
          />
        </TabPanel>
        <TabPanel>
          <slideshow-ads
            @go-to-profile="goToProfile"
            @update-selected-sub-tab="updateSelectedSubTab"
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </main>
</template>

<script lang="ts">
import { useAccountStore, useSelectTabStore } from '@/store/accounts'
import { useAdminStore } from '@/store/admin/adminStore'
import { useCableStore } from '@/store/cableStore'
import { useConsumerStore } from '@/store/consumerStore'
import { useToastStore } from '@/store/toastStore'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { computed } from 'vue'
import { Component, Provide, Vue, toNative } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { useErrorStore } from '../../store/errorStore'
import Analytics from '../Analytics.vue'
import Customers from '../Customers.vue'
import SlideshowAds from '../SlideshowAds.vue'
import StyleRequests from '../StyleRequests.vue'
import Subscribers from '../Subscribers.vue'

  @Component({
    components: {
      StyleRequests,
      Subscribers,
      Customers,
      TabGroup,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      Analytics,
      SlideshowAds,
    },
  })
  class AdminPage extends Vue {
    selectTabStore = useSelectTabStore()
    accountStore = useAccountStore()
    adminStore = useAdminStore()
    errorStore = useErrorStore()
    router = useRouter()
    selectedTab = computed(() => this.selectTabStore.getSelectedTab)
    adminSubscription: any = null
    consumerStore = useConsumerStore()
    cableStore = useCableStore();
    portfoliosSessionFetched = false

    @Provide
    selectedSubTab = this.selectTabStore.getSelectedSubTab

    changeTab(index: number) {
      const { updateSelectedTab,updateSelectedSubTab } = this.selectTabStore
      updateSelectedSubTab(0)
      updateSelectedTab(index)
    }

    updateSelectedSubTab(index: number) {
      const { updateSelectedSubTab } = this.selectTabStore
      updateSelectedSubTab(index)
    }

    get tabHeadlines() {
      return ['Subscribers', 'Upload requests', 'Analytics', 'Slideshow ads']
    }

    allowPortfolios({ account_id }: { account_id: string }) {
      this.adminStore.ALLOW_PORTFOLIOS({
        account_id,
      })
      this.adminStore.$subscribe(
        (
          _mutation: any,
          state: { status: number; message: any }
        ) => {
          if (state.status === 200 && state.message) {
            useToastStore().SHOW_NOTIFICATION(state.message, 'success', true, false)

            this.adminStore.$patch(
              (state: {
                status: number
                message: string
              }) => {
                state.status = 0
                state.message = ''
              }
            )
            this.accountStore.GET_CURRENT_ACCOUNT({
              account_id,
            })
            this.adminStore.GET_CURRENT_ACCOUNT_PORTFOLIOS({
              account_id,
            })
          }
        }
      )
    }

    goToProfile({
      account_id,
      gotToTab,
    }: {
      account_id: string
      gotToTab?: number
    }) {
      this.accountStore.$reset()
      this.selectTabStore.updateSelectedSubTab(1)

      if (typeof gotToTab === 'number') {
        this.selectTabStore.updateSelectedTab(gotToTab)
      }

      this.accountStore.GET_CURRENT_ACCOUNT({
        account_id,
      })
      this.adminStore.GET_CURRENT_ACCOUNT_PORTFOLIOS({
        account_id,
      })
      this.router.push({ query: { account_id } })
    }
  }
  export default toNative(AdminPage)
</script>

<style></style>
