<template>
  <div id="app">
    <div class="error-container">
      <p class="error-code">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </p>
      <div class="error-title">ERROR</div>
      <div class="error-message">PAGE NOT FOUND</div>
      <div class="error-message">
        I think you just went to a page non-existing page.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  export default {}
</script>

<style lang="scss">
  $wave-time: 600ms;
  $wave-size: 1vmin;
  $wave-seq-list: 0vmin, -$wave-size, 0vmin, $wave-size, 0vmin, -$wave-size,
    0vmin;

  $search-size: 2.8rem;

  $search-icon-rotate: -38deg;

  $search-show-time: 375ms;
  $search-fade-time: 256ms;
  $search-move-time: 32ms;

  $search-box-size: $search-size;

  $search-window-shown-width: calc(100vw - 4rem);
  $search-window-shown-height: calc(100vh - 4rem);
  $search-window-shown-top: 2rem;
  $search-window-shown-left: 2rem;
  $search-window-show-time: 512ms;

  @mixin input-field($padding) {
    font-family: inherit;
    font-size: inherit;
    padding: $padding;
    border: 0;
    outline: 0;
    margin: 0;
    box-sizing: border-box;
  }

  @mixin screen-text($font-size) {
    color: #ffffff;
    font-family: 'Fredoka One', cursive;
    font-size: $font-size;
    text-align: center;
    text-shadow: calc($font-size / 15) calc($font-size / 15) rgba(#303030, 0.5);
    user-select: none;
  }

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700');
  @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

  body {
    width: 100vw;
    height: 100vh;
    font-size: 16px;
  }

  #app {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .error-container {
    background-image: linear-gradient(to bottom right, #6200c4, #ed6f00);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    cursor: none;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .error-code {
    @include screen-text(24vmin);
    padding: 1.5vmin 5vmin;
    display: flex;
    span {
      font-weight: bold;
      display: block;
    }
    @for $i from 1 through 3 {
      span:nth-child(#{$i}) {
        animation: error-code-#{$i} $wave-time linear infinite;
      }
    }
  }

  @for $i from 1 through 3 {
    @keyframes error-code-#{$i} {
      @for $j from 1 through 5 {
        #{($j - 1) * 25}% {
          transform: translateY(nth($wave-seq-list, $j + ($i - 1)));
        }
      }
    }
  }

  .error-title {
    @include screen-text(2.8rem);
    padding: 1.5vmin 10vmin;
  }

  .error-message {
    @include screen-text(1.2rem);
    padding: 1.5vmin 30vmin;
  }
</style>
