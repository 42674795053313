<script setup lang="ts">
import { logoY } from '@/helpers'
import { usePortfoliosStore } from '@/store/portfolios'
import { generateCacheBuster } from '@/utils/cacheBuster'
import { onMounted, ref, watch } from 'vue'

const portfoliosStore = usePortfoliosStore()

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
  portfolioId: {
    type: String,
    required: false,
    default: () => '',
  },
  dataE2e: {
    type: String,
    default: '',
    required: false,
  },
})
const emit = defineEmits(['imageIsReady']);
const isLoading = ref(true)
const isAnErrorValue = ref(false)
const isImageVisible = ref(false)
const wrapperRef = ref<HTMLDivElement | null>(null)

const uniqueImageUrl = ref('')

watch(
  () => props.imageUrl,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      uniqueImageUrl.value = `${newValue}?cache-buster=${generateCacheBuster()}`
    }
  },
  { immediate: true }
)

function handleImageError(event: Event) {
  isAnErrorValue.value = true
  portfoliosStore.DELETE_AND_UPLOAD_VARIANT({
    portfolio_id: props.portfolioId.toString(),
  })
}

function handleImageLoad(event: Event) {
  const img = event.target as HTMLImageElement
  if (img.complete && img.naturalWidth > 0) {
    isLoading.value = false
    emit('imageIsReady', true)
    document.documentElement.style.setProperty(
      '--swiper-slide-active-after-display',
      'block'
    )
  }
}

watch(isLoading, (newValue) => {
  if (newValue) {
    document.documentElement.style.setProperty(
      '--swiper-slide-active-after-display',
      'none'
    )
  }
})

function observeImage() {
  if (!wrapperRef.value) return

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        isImageVisible.value = true
        observer.disconnect()
      }
    },
    { threshold: 1.0 }
  )

  if (wrapperRef.value instanceof HTMLElement) {
    observer.observe(wrapperRef.value)
  }
}

onMounted(() => {
  uniqueImageUrl.value = `${props.imageUrl}?cache-buster=${generateCacheBuster()}` // Initialize uniqueImageUrl once
  observeImage()
})
// Expose the error and isAnErrorValue to the parent component for testing purposes
defineExpose({ isLoading, isAnErrorValue })
</script>

<template>
  <div
    ref="wrapperRef"
  >
    <img
      :src="uniqueImageUrl"
      @load="handleImageLoad"
      :alt="alt"
      @error="handleImageError"
      :data-e2e="dataE2e"
    >
    <div v-if="isLoading" class="is-ready">
      <img
        :src="logoY"
        :srcset="logoY"
        alt="loader"
        class="loader-logo"
      >
      <div class="is-loader" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text {
  margin-bottom: 0.7rem;
  font-size: 2rem;
  margin-left: 0.5rem;
}

.slideshow .swiper-slide .loader-logo {
  width: unset;
  height: 40%;
}

.is-ready {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #0000;
  margin: auto;
  z-index: 2; /* Ensure loader is above the actual image */
}

.is-loader {
  width: 40px;
  margin-left: -4px;
  margin-bottom: -40%;
  aspect-ratio: 4;
  background: url('/src/assets/logo-dot.svg') 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 900ms steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
</style>
