import { isAdmin } from '@/helpers/index'
import { useAuthTokenStore } from '@/store/authToken'
import { useToastStore } from '@/store/toastStore'
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import routesNames from './routesNames.d'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routesNames.home,
    component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
  },
  {
    path: '/tv',
    name: routesNames.tvLogin,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "UserLoginView" */ '../views/UserLoginView.vue')
  },
  {
    path: '/:barbershop_name/stylist/:stylist_id',
    name: routesNames.stylist,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "SlideShowView" */ '../views/SlideShowView.vue'),
    props: route => {
      return { barbershop_name: route.params.barbershop_name, stylist_id: route.params.stylist_id }
    }
  },
  {
    path: '/:account_id/styles/:style_id?',
    name: routesNames.addOrEditStyle,
    sensitive: true,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "AddOrEditStyleView" */ '../views/AddOrEditStyleView.vue'),
    props: route => {
      return { account_id: route.params.account_id, style_id: route.params.style_id }
    }
  },
  {
    path: '/:account_id/style/:style_id?',
    name: routesNames.removeStyle,
    sensitive: true,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "RemoveStyleView" */ '../views/RemoveStyleView.vue'),
    props: route => {
      return { account_id: route.params.account_id, style_id: route.params.style_id }
    }
  },
  {
    path: '/:account_id/portfolios',
    name: routesNames.portfolios,
    sensitive: true,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "PortfolioView" */ '../views/PortfolioView.vue'),
    props: route => {
      return { account_id: route.params.account_id }
    }
  },

  {
    path: '/login',
    name: routesNames.login,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "UserLoginView" */ '../views/UserLoginView.vue')
  },

  {
    path: '/imprint',
    name: routesNames.imprint,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "ImprintView" */ '../views/ImprintView.vue')
  },
  {
    path: '/data-privacy',
    name: routesNames.dataPrivacy,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () =>
      import(/* webpackChunkName: "DataPrivacyView" */ '../views/DataPrivacyView.vue')
  },
  {
    path: '/cookies',
    name: routesNames.cookies,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "CookiesView" */ '../views/CookiesView.vue')
  },
  {
    path: '/legal',
    name: routesNames.legal,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "LegalView" */ '../views/LegalView.vue')
  },
  {
    path: '/copyright',
    name: routesNames.copyright,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "CopyrightView" */ '../views/CopyrightView.vue')
  },
  {
    path: '/logout',
    name: routesNames.logout,
    sensitive: true,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "UserLogoutView" */ '../views/UserLogoutView.vue')
  },
  {
    path: '/register',
    name: routesNames.register,
    meta: { anonymousOnly: true },
    component: () =>
      import(/* webpackChunkName: "UserRegisterView" */ '../views/UserRegisterView.vue')
  },
  {
    path: '/password/reset',
    name: routesNames.passwordReset,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () =>
      import(/* webpackChunkName: "PasswordResetView" */ '../views/PasswordResetView.vue')
  },
  {
    path: '/accounts/:account_id',
    name: routesNames.accounts,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "AccountsView" */ '../views/AccountsView.vue'),
    props: route => {
      return { account_id: route.params.account_id }
    }
  },
  {
    path: '/:barbershop_name/me',
    name: routesNames.aboutMe,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "AboutMeView" */ '../views/AboutMeView.vue'),
    props: route => {
      return { barbershop_name: route.params.barbershop_name }
    }
  },
  {
    path: '/:fullname_account_id',
    name: routesNames.slideShow,
    sensitive: true,
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "SlideShowView" */ '../views/SlideShowView.vue'),
    props: route => {
      return {
        fullname_account_id: route.params.fullname_account_id
      }
    }
  },
  {
    path: '/:barbershop_name/:portfolio_id/style',
    name: routesNames.styleDetailPage,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "DetailsView" */ '../views/DetailsView.vue'),
    props: route => {
      return {
        barbershop_name: route.params.barbershop_name,
        portfolio_id: route.params.portfolio_id
      }
    }
  },
  {
    path: '/:account_id/qr_code',
    name: routesNames.qrCode,
    sensitive: true,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "QRCodeView" */ '../views/QRCodeView.vue'),
    props: route => {
      return { account_id: route.params.account_id }
    }
  },
  {
    path: '/admin',
    name: routesNames.admin,
    sensitive: true,
    meta: { requiresAuth: true, admin: true },
    component: () => import(/* webpackChunkName: "QRCodeView" */ '../views/AdminView.vue')
  },
  {
    path: '/confirm_email',
    name: routesNames.confirmEmail,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "confirmEmail" */ '../views/ConfirmEmailView.vue')
  },
  {
    path: '/404',
    name: routesNames.error_404,
    sensitive: true,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "confirmEmail" */ '../views/Error404View.vue')
  },
  {
    path: '/:catchAll(.*)*',
    name: routesNames.PageNotFound,
    meta: { anonymousOnly: true },
    component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true
})

// const requiresAuthGuard = (
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized,
//   next: NavigationGuardNext,
//   authTokenStore: any
// ): boolean => {
//   if (to.matched.some((record) => record.meta.requiresAuth && record.meta.admin)) {
//     if (isAdmin(authTokenStore.currentUser.abilities)) {
//       next()
//     } else {
//       next({ name: routesNames.home })
//     }
//     return true
//   }
//   if (to.matched.some((record) => record.meta.requiresAuth && !record.meta.admin)) {
//     if (authTokenStore.isUserLoggedOut && authTokenStore.isTokenExpired) {
//       next({ name: routesNames.login, query: { redirect: to.fullPath } })
//     } else {
//       next()
//     }

//     return true
//   }
//   return false
// }

// const anonymousOnlyGuard = (
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized,
//   next: NavigationGuardNext,
//   authTokenStore: any
// ): boolean => {
//   if (to.matched.some((record) => record.meta.anonymousOnly)) {
//     const isAnonymous = authTokenStore.isUserLoggedOut
//     if (!isAnonymous) {
//       next({
//         name: routesNames.home,
//       })
//     } else {
//       next()
//     }
//     return true
//   }
//   return false
// }

// router.beforeEach(async (to, from, next) => {
//   const authTokenStore = useAuthTokenStore()
//   if (to.name !== from.name) {
//     useResetStore().useToastStore()
//     next()
//   }
//   if (requiresAuthGuard(to, from, next, authTokenStore)) {
//     return
//   }
//   if (anonymousOnlyGuard(to, from, next, authTokenStore)) {
//     return
//   }
//   next()
// })

router.beforeEach((to, _from, next) => {
  const authTokenStore = useAuthTokenStore()
  if ((to.name === 'login' || to.name === 'register') && !authTokenStore.isUserLoggedOut) {
    useToastStore().SHOW_NOTIFICATION("You're already logged in", 'warning', true, false)
    next({ name: routesNames.home })
  } else if (
    to.name !== 'login' &&
    to.meta.requiresAuth &&
    authTokenStore.isUserLoggedOut &&
    authTokenStore.isTokenExpired
  )
    next({ name: 'login', query: { redirect: to.fullPath } })
  else if (to.meta.admin && !isAdmin(authTokenStore.currentUser.abilities))
    next({ name: routesNames.home })
  else {
    // useResetStore().toastStore()
    next()
  }
})

export { routesNames }
export default router
