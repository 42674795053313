<template>
  <div>
    <div v-if="accountStore.isLoading" class="center">
      <orbit-spinner :animation-duration="1500" :size="64" :color="'#F99FB5'" />
    </div>
    <main v-else class="text-left">
      <h2>Company info</h2>
      <p>Company name: <b>{{ currentAccountAnalyticInfos.company }}</b></p>
      <p>Company address: <b>{{ currentAccountAnalyticInfos.address }}, {{ currentAccountAnalyticInfos.zip_code }} {{ currentAccountAnalyticInfos.city }}</b></p>
      <p>Company email: <b>{{ currentAccountAnalyticInfos.email }}</b></p>
      <p>Company website: <b>{{ currentAccountAnalyticInfos.domain }}</b></p>

      <hr>
      <h2>Slideshow runtime</h2>
      <p>Slideshow status: <b>{{ currentAccountAnalyticInfos.status }}</b></p>
      <p>Slideshow last online session: <b>{{ dateTime(currentAccountAnalyticInfos.online_session) }}</b></p>
      <p>Slideshow last offline session: <b>{{ dateTime(currentAccountAnalyticInfos.offline_session) }}</b></p>
      <p>Slideshow runtime today: <b>{{ currentAccountAnalyticInfos.runtime_today }}</b></p>
      <p>Slideshow runtime this month: <b>{{ currentAccountAnalyticInfos.runtime_last_month }}</b></p>
      <p>Slideshow runtime total: <b>{{ currentAccountAnalyticInfos.runtime_total }}</b></p>
      <p>Slideshow Last Online At: <b>{{ currentAccountAnalyticInfos?.last_online_at }}</b></p>

      <hr>
      <h2>Browser info</h2>
      <div v-if="currentAccountAnalyticInfos.browser_info">
        <p>Browser: <b>{{ currentAccountAnalyticInfos.browser_info.browser["name"] }}</b></p>
        <p>Browser version: <b>{{ currentAccountAnalyticInfos.browser_info.browser["version"] }}</b></p>
        <p>OS: <b>{{ currentAccountAnalyticInfos.browser_info.os["name"] }}</b></p>
        <p>OS version: <b>{{ currentAccountAnalyticInfos.browser_info.os["version"] }}</b></p>
      </div>
      <div v-else>
        <p>Browser info not available</p>
      </div>

      <hr>
      <h2>Screen Info</h2>
      <div v-if="currentAccountAnalyticInfos.screen_info">
        <p>Screen width: <b>{{ currentAccountAnalyticInfos.screen_info.width }}</b></p>
        <p>Screen height: <b>{{ currentAccountAnalyticInfos.screen_info.height }}</b></p>
        <p>Screen orientation: <b>{{ currentAccountAnalyticInfos.screen_info.orientation }}</b></p>
        <p>Screen resolution: <b>{{ currentAccountAnalyticInfos.screen_info.resolution }}</b></p>
      </div>
      <div v-else>
        <p>Screen info not available</p>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { dateTime } from '@/helpers'
import { useAccountStore } from '@/store/accounts'
import { OrbitSpinner } from 'epic-spinners'
import { Component, Inject, Vue } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'

@Component({
  components: {
    OrbitSpinner,
  },
})
class AnalyticProfileDetails extends Vue {
  accountStore = useAccountStore()
  route = useRoute()

  @Inject
  analytics!: () => any[]

  get currentAccountAnalyticInfos() {
    const accountId = this.route.query.account_id as string
    return this.analytics().find((analytic: any) => analytic.account_id === accountId) || {}
  }

  dateTime(date?: Date) {
    return date ? dateTime(date) : 'N/A'
  }
}

export default AnalyticProfileDetails
</script>

<style lang="scss">
</style>
