import { mapStatus } from '@/helpers'
import { defineStore } from 'pinia'
import { POSITION, useToast } from 'vue-toastification'
import { ActionTypes } from './action-types'

const toast = useToast()

export const useToastStore = defineStore('toastStore', {
  actions: {
    [ActionTypes.SHOW_NOTIFICATION] (
      message: string,
      status: string,
      icon: boolean,
      noAutoHide: boolean
    ) {
      // Clear all existing toasts
      toast.clear()

      // Show the new toast notification
      toast(message, {
        position: POSITION.TOP_CENTER,
        timeout: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        hideProgressBar: false,
        closeButton: 'button',
        type: mapStatus(status),
        icon: icon,
        showCloseButtonOnHover: noAutoHide,
      })
    },
  },
  persist: false,
})
