<template>
  <div>
    <CreateSingleUpload
      :image-title="imgTitle"
      :is-loading="accountStore.isLoading"
      :default-image="
        accountStore.current_account.attributes.avatar || defaultImage
      "
      @create-single="uploadCreateSingle"
      @delete-single="deleteCreateSingle"
      @is-converting="$emit('isConverting', $event)"
    />
  </div>
</template>
<script lang="ts">
  import iconUrl from '@/assets/placeholder-ratio_1-1.png'
import { useAccountStore } from '@/store/accounts'
import { useAuthTokenStore } from '@/store/authToken'
import { useToastStore } from '@/store/toastStore'
import type { AccountType } from '@/types'
import { Component, Inject, Prop, toNative, Vue } from 'vue-facing-decorator'
import CreateSingleUpload from './single-upload/createSingle.vue'

  @Component({
    components: { CreateSingleUpload },
    emits: ['update:handleAccount', 'isConverting'],
  })
  class SingleUpload extends Vue {
    @Inject
    currentAccount!: () => AccountType

    @Prop({
      default: iconUrl,
    })
    defaultImage!: string
    @Prop({
      default: 'Choose image',
    })
    imgTitle!: string

    accountStore = useAccountStore()
    authTokenStore = useAuthTokenStore()

    uploadCreateSingle(file: string | Blob) {
      const formData = new FormData()
      formData.append('avatar', file)

      this.accountStore.UPLOAD_AVATAR({
        data: formData,
      })

      this.accountStore.$subscribe(
        (
          _mutation: any,
          state: { status: number; message: any }
        ) => {
          if (state.status === 200 && state.message) {
            useToastStore().SHOW_NOTIFICATION(state.message, 'success', true, false)

            this.accountStore.$patch(
              (state: {
                status: number
                message: string
              }) => {
                state.status = 0
                state.message = ''
              }
            )
          }
        }
      )
    }
    deleteCreateSingle() {
      this.accountStore.DELETE_AVATAR()
    }
  }
  export default toNative(SingleUpload)
</script>
