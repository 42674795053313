<template>
  <div v-if="show" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" @click="closeModal">
        </button>
      </div>
      <div class="modal-body">
        <LegalContent />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeModal">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import LegalContent from '../LegalContent.vue';


const props = defineProps<{
  show: boolean;
}>();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.terms-of-use {
  margin: 0 3vw;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-title {
  margin: 0;
}

.close {
  cursor: pointer;
  font-size: 20px;
  border: none;
  background: none;
}

.modal-body {
  margin-bottom: 20px;
  overflow-y: auto; /* Add vertical scrolling */
  max-height: 400px; /* Adjust the max-height as needed */
}

.modal-footer {
  text-align: right;
}
</style>
