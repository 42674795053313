<template>
  <div>
    <main>
      <h1>
        <span @click="updateSelectedSubTab(0)">Slideshow Ads</span>
      </h1>

      <multi-upload
        :action-type="'create'"
        @is-converting="onConverting"
      />
      <button
        :disabled="isConverting"
        class="btn btn-primary my-5"
        :tab-index="1"
        @click.prevent="handelImageUpload"
      >
        Save
        <span :class="isConverting && 'loader'" />
      </button>

      <hr>
      <br>

      <data-table
        :items="slideshowAds()"
        :fields="fields"
      >
        <template #cell(cover)="{ item, value }">
          <c-video
            v-if="getExtension(item.filename).type === 'video'"
            class="slide-img"
            :content-type="getExtension(item.filename).mineType"
            :video-url="item.file"
          />
          <c-image
            v-else
            :image-url="value"
            :alt="'ads'"
          />
        </template>
        <template #link="{ item }">
          <img
            src="/src/assets/btn-delete.png"
            srcset="/src/assets/btn-delete@2x.png 2x"
            alt="Delete Ad"
            @click.prevent="deleteAds(item.id)"
          >
        </template>
      </data-table>
    </main>
  </div>
</template>

<script lang="ts">
  import {
  CImage,
  CVideo,
  DataTable,
  Input as IInput,
  MultiUpload,
} from '@/components'
import { getExtension } from '@/helpers'
import { useAdStore } from '@/store/admin/adStore'
import { useToastStore } from '@/store/toastStore'
import { PortfolioType } from '@/types'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { computed, ref } from 'vue'
import {
  Component,
  Emit,
  Inject,
  Vue,
  Watch,
  toNative,
} from 'vue-facing-decorator'
import { useSelectTabStore } from '../store/accounts/selectTabStore'
import { useAdminStore } from '../store/admin/adminStore'
import { useErrorStore } from '../store/errorStore'
import { usePortfoliosStore } from '../store/portfolios/portfolios'
import { useResetStore } from '../utils/useResetStore'
import SubscriberInformation from './SubscriberInformation.vue'

  @Component({
    components: {
      DataTable,
      SubscriberInformation,
      TabGroup,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      MultiUpload,
      CImage,
      CVideo,
      IInput,
    },
  })
  class SlideshowAds extends Vue {
    @Inject
    slideshowAds!: () => void

    title = ref('')

    isConverting = false
    portfoliosStore = usePortfoliosStore()
    adminStore = useAdminStore()
    adStore = useAdStore()
    errorStore = useErrorStore()
    submitted = false

    selectedSubTab = computed(
      () => useSelectTabStore().getSelectedSubTab
    ) as unknown as number

    @Watch('portfoliosStore.portfolio.attributes.payload', {
      immediate: true,
      deep: true,
    })
    onFileChange() {
      if (this.portfoliosStore.portfolio.attributes.payload.length > 0) {
        this.submitted =
          this.portfoliosStore.portfolio.attributes.payload[0].file !==
          undefined
      }
    }

    @Emit
    goToProfile(account_id: string) {
      return { account_id }
    }

    getExtension(file: string) {
      return getExtension(file)
    }

    onConverting(value: boolean) {
      this.isConverting = value
    }

    @Emit
    updateSelectedSubTab(index: number) {
      return index
    }

    deleteAds(id: string) {
      this.adStore.DELETE_SLIDESHOW_ADS(id)
      this.adStore.$subscribe(
        (
          mutation: any,
          state: {
            status: number
            message: string
          }
        ) => {
          if (state.status === 200 && state.message === 'Ad has been deleted') {
            useToastStore().SHOW_NOTIFICATION(state.message, 'success', true, false)
            this.adStore.$patch(
              (state: {
                status: number
                message: string
              }) => {
                state.status = 0
                state.message = ''
              }
            )
            this.adStore.GET_PORTFOLIO_ADS()
          }
        }
      )
    }

    handelImageUpload() {
      if (this.submitted) {
        this.isConverting = true
        const formData = new FormData()
        const payload = this.portfoliosStore.portfolio.attributes.payload

        formData.append('images', payload[0].file as unknown as string)

        this.adStore.CREATE_SLIDESHOW_ADS({
          ad_data: formData,
        })

        this.adStore.$subscribe(
          (
            mutation: any,
            state: {
              status: number
              message: string
              user_interaction_portfolio: boolean
              isRequestLoading: boolean
            }
          ) => {
            if (
              state.status === 201 &&
              state.message === 'Ad has been created'
            ) {
              useToastStore().SHOW_NOTIFICATION(state.message, 'success', true, false)
              this.isConverting = false
              useResetStore().portfoliosStore()
              this.portfoliosStore.$patch(
                (state: { portfolio: PortfolioType }) => {
                  state.portfolio.attributes.payload = []
                }
              )
              this.adStore.$patch(
                (state: {
                  status: number
                  message: string
                  user_interaction_portfolio: boolean
                  isRequestLoading: boolean
                }) => {
                  state.status = 0
                  state.message = ''
                  state.user_interaction_portfolio = false
                  state.isRequestLoading = false
                }
              )
              this.adStore.GET_PORTFOLIO_ADS()
            }
          }
        )
      }
    }

    @Watch('$route.name', { immediate: true, deep: true })
    updatePageTitle(name: string) {
      if (name === 'admin') {
        document.title = `Admin - Slideshow Ads`
      }
    }

    fields = [
      { key: 'id', label: 'ID' },
      { key: 'title', label: 'Title' },
      { key: 'cover', label: 'Image' },
      { key: 'delete', label: 'Delete' },
    ]
  }
  export default toNative(SlideshowAds)
</script>

<style scoped>
  .icon-container {
    width: 10px;
    height: 10px;
  }
  .status-online {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: green;
    border: 2px solid green;
  }
  .status-offline {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(240, 14, 14);
    border: 2px solid rgb(240, 14, 14);
  }
</style>
