<template>
  <div class="terms-of-use">
    <h1>Terms of Use</h1>
    <p>
      This present sets out the terms of use of the website http://www.ypony.de,
      http://www.ypony.com owned and operated by Zlonc Limited, a company with
      registered address at: Griva Digeni 51, Athineon Court, Office 202, 8047
      Paphos, Cyprus (“ZLONC” or “We” or “Us”).
    </p>
    <p>
      This present is a legally binding Agreement (the “Agreement”). By using
      the Website, you (“You” or the “User”) accept entering into the Agreement
      and accept the Terms of Use set out herein. If you do not agree with any
      of the terms of this present, you may not use or otherwise access the
      Website and its Services.
    </p>
    <h3>1. General</h3>
    <p>
      By using the Website, you accept this Agreement and our Privacy Policy.
      Your relationship with Us will be subject only to this Agreement and
      Privacy Policy. This Agreement and Privacy Policy are available on our
      website.
    </p>
    <p>Other terms and conditions may be applied by the application stores.</p>
    <p>
      The use and access of the Website is for your own personal use as an
      individual and no commercial use of the Website is permitted.
    </p>
    <p>
      You may only access the Website if you are 18 years or older. We may
      require proof of your identity to verify your age.
    </p>
    <h3>2. Amendment or Termination</h3>
    <p>
      We reserve the right to amend, modify, alter, suspend, terminate or
      discontinue the Website and/or the services and facilities provided
      thereon or any part thereof without notice. We shall not be liable to you
      or any third party for any amendment, modification, alteration,
      suspension, termination or discontinuation of the Website. You acknowledge
      and understand that you forfeit any right to any refund, compensation or
      any other right whatsoever in the event of amendment, modification,
      alteration, suspension, termination or discontinuation of the Website.
    </p>
    <h3>3. Intellectual Property</h3>
    <p>
      Please refer to the Copyright section of our website. By using our
      Website, you agree to the terms and conditions of the Copyright section of
      our website.
    </p>
    <h3>4. Local laws</h3>
    <p>
      You shall be solely responsible for complying with all applicable laws in
      relation to the use of the Website and its content that are applicable.
    </p>
    <h3>5. Prohibited Activities:</h3>
    <p>
      You agree not to engage in any activity on the Website that violates any
      applicable laws, regulations, or this present Agreement, including
      hacking, spamming and distribution of malware.
    </p>
    <h3>6. Termination</h3>
    <p>
      We reserve the right to terminate the Agreement at any time without notice
      in the event any of its terms and conditions are breached by you.
    </p>
    <p>
      On termination of the Agreement, you will no longer have access to the
      Website and its content.
    </p>
    <h3>7. Limitation of Liability and Indemnity</h3>
    <p>
      You agree to defend, indemnify and hold us harmless from and against any
      claims, suits, losses, damages, liabilities, costs, and expenses
      (including reasonable attorneys’ fees) brought by third parties resulting
      from or relating to your use of the Website and/or its content, and/or
      your violation of any terms of this Agreement.
    </p>
    <p>
      Our Website may contain links to other websites developed, owned or
      maintained by third parties over whom the Company does not have control.
      We make no representations over the accuracy or nature of the content of
      third party websites and we have no control over their privacy policies.
      We take no responsibility and shall not be liable in any manner whatsoever
      for any damages you may incur by the use of any third party website linked
      on our Website.
    </p>
    <p>
      We shall not be liable for any losses and/or damages of any kind including
      but not limited to compensatory, direct, indirect or consequential
      damages, loss of data, income or profit, loss of or damage to property and
      claims of third parties, in connection with the use of our website as well
      as the information, data and content of our Website.
    </p>
    <p>
      the information, data and content of our Website. We reserve the right to
      update this disclaimer as well as our privacy policy from time to time and
      any updates, revisions or amendments shall be published on this website
      promptly.
    </p>
    <h3>8. Disclaimers</h3>
    <p>
      THE WEBSITE AND THE CONTENT ARE PROVIDED “AS-IS” AND AS AVAILABLE AND WE
      EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
      EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
      ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE WEBSITE AND/OR
      THE CONTENT WILL MEET YOUR REQUIREMENTS; OR WILL BE AVAILABLE ON AN
      UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR WILL BE ACCURATE,
      RELIABLE, COMPLETE, LEGAL, OR SAFE.
    </p>
    <h3>9. Limitation on Liability</h3>
    <p>
      IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
      PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
      PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR CONTENT,
      EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS
      TO, AND USE OF, THE WEBSITE AND/OR THE CONTENT ARE AT YOUR OWN DISCRETION
      AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE
      OR FOR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE
      CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING
      FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS
      OF THE FORM OF THE ACTION) WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU
      HAVE PAID TO US IN THE PRECEDING 12 MONTHS (IF ANY). SOME JURISDICTIONS DO
      NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF
      CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY
      TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM
      JURISDICTION TO JURISDICTION.
    </p>
    <h3>10. Governing Law</h3>
    <p>
      This Agreement shall be governed by the laws of the Republic of Cyprus.
      Any dispute among the parties relying on the present Agreement shall be
      exclusively resolved by the competent Courts of the Republic of Cyprus and
      Cyprus laws shall be applicable.
    </p>
    <h3>11. Notices</h3>
    <p>Any notices shall be sent in writing to the email hi@ypony.com .</p>
    <h3>12. Severability</h3>
    <p>
      If any provision of this Agreement is, for any reason, held to be invalid
      or unenforceable, the other provisions of this Agreement will be
      unimpaired, and the invalid or unenforceable provision will be deemed
      modified so that it is valid and enforceable to the maximum extent
      permitted by law.
    </p>
    <h3>13. Entire Agreement</h3>
    <p>
      This Agreement contains the entire agreement between the parties hereto
      with respect to the subject matter of this Agreement and supersedes any
      and all prior or contemporaneous agreements and discussions, whether
      written or oral, express or implied.
    </p>
    <h3>14. Assignment</h3>
    <p>
      We may assign this Agreement partially or in whole without your consent.
      The terms of this Agreement shall be binding upon assignees. You may not
      assign this Agreement partially or in whole without our consent.
    </p>
    <h3>15. Compliance</h3>
    <p>
      You represent and warrant that you are not located in a country that is
      subject to a United States of America Government or European Union embargo
      or has been designated by the as a “terrorist supporting” country; and
      that you are not listed on any United States of America Government or
      European Union list of prohibited, sanctioned or restricted parties.
    </p>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.terms-of-use {
  margin: 0 10vw;
  width: auto;
  p {
    text-align: left;
  }
  p.yellow {
    background-color: rgb(242, 242, 5);
  }
  h3 {
    text-align: left;
  }
}
</style>
