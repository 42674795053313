import type {
  AccountType,
  ApiErrorResponse,
  JWTType,
  PortfolioAccountType,
  PortfolioPayloadType,
  PortfolioType,
  PortfoliosSessionsType,
  PortfoliosType,
  ReferralUsers,
  SubscribersType,
  UserType
} from '@/types'
import { reactiveComputed } from '@vueuse/core'
import type { AxiosError } from 'axios'

export interface State {
  selected_tab: number
  selected_sub_tab: number
  imageAction: string
  message: string
  user_ip: string
  isConnected: boolean
  opt_in_capturing: boolean
  reconnectError: boolean
  heartBeatInterval: number
  heartBeatTimer: number
  create_single: string
  error: ApiErrorResponse | AxiosError
  isRequestLoading: boolean
  isLoading: boolean
  controller: AbortController
  errors: ApiErrorResponse
  status: number
  statusText: string
  portfolio: PortfolioType
  portfolios: PortfoliosType
  referral_users: ReferralUsers
  subscribers: SubscribersType[]
  portfolios_sessions: PortfoliosSessionsType[]
  createPortfolio: {
    payload: PortfolioPayloadType[]
  }
  isDeleted: boolean
  updatePortfolio: {
    plan_name: {
      name: string
      label: string
      isSelected: boolean
    }[]
    tags: string[]
    payload: PortfolioPayloadType[]
  }
  portfolio_ads: PortfolioPayloadType[]
  isNavOpen: boolean
  counter: number
  authToken: boolean
  user_interaction_portfolio: boolean
  isUserLoggedOut: boolean
  jwt: JWTType
  barbers: {
    id: number
    user_id: number
    barberName: string
    portfolio: {
      first_name: string
      last_name: string
      aboutMe: string
      priceRange: string
      location: string
      facebook: {
        active: boolean
        default: string
      }
      whatsapp: {
        active: boolean
        default: string
      }
      telegram: {
        active: boolean
        default: string
      }
      telephone: string
      profileImage: string
      cardImages: string[]
      galleryImages: string[]
    }
    tags: string[]
  }[]
  current_user: UserType
  current_account: AccountType
  slideshow_data: {
    slideshow_path: string
    socket_id: string
  }
}
export const state = reactiveComputed(() => ({
  selected_tab: 0,
  selected_sub_tab: 0,
  imageAction: '',
  message: '',
  create_single: '',
  user_ip: '',
  isConnected: false,
  opt_in_capturing: true,
  reconnectError: false,
  heartBeatInterval: 50000,
  heartBeatTimer: 0,
  error: undefined,
  isRequestLoading: true,
  isLoading: false,
  isDeleted: false,
  controller: new AbortController(),
  errors: {} as ApiErrorResponse,
  status: 0,
  slideshow_data: {} as State['slideshow_data'],
  referral_users: {} as ReferralUsers,
  statusText: '',
  portfolio: {
    id: '',
    type: '',
    attributes: {
      gender: '',
      color: '',
      style: '',
      images: [''],
      plan_name: [
        {
          name: 'style_one_day',
          label: 'Promote for<br />9.90 € for 1 day.',
          isSelected: false
        },
        {
          name: 'style_three_days',
          label: 'Promote for<br />29.70 € for 3 days.',
          isSelected: false
        },
        {
          name: 'style_seven_days',
          label: 'Promote for<br />69.30 € for 7 days.',
          isSelected: false
        }
      ],
      is_approved: false,
      account: {} as AccountType['attributes'],
      tags: [''],
      payload: [] as PortfolioPayloadType[]
    }
  },
  portfolio_ads: [] as PortfolioPayloadType[],
  portfolios: [
    {
      id: '',
      type: '',
      attributes: {
        gender: '',
        color: '',
        style: '',
        images: [''],
        plan_name: [
          {
            name: 'style_one_day',
            label: 'Promote for<br />9.90 € for 1 day.',
            isSelected: true
          },
          {
            name: 'style_three_days',
            label: 'Promote for<br />29.70 € for 3 days.',
            isSelected: false
          },
          {
            name: 'style_seven_days',
            label: 'Promote for<br />69.30 € for 7 days.',
            isSelected: false
          }
        ],
        is_approved: false,
        account: {} as PortfolioAccountType,
        tags: [''],
        payload: [] as PortfolioPayloadType[]
      }
    }
  ],
  createPortfolio: {
    payload: [] as PortfolioPayloadType[]
  },
  updatePortfolio: {
    plan_name: [
      {
        name: 'style_one_day',
        label: 'Promote for<br />9.90 € for 1 day.',
        isSelected: false
      },
      {
        name: 'style_three_days',
        label: 'Promote for<br />29.70 € for 3 days.',
        isSelected: false
      },
      {
        name: 'style_seven_days',
        label: 'Promote for<br />69.30 € for 7 days.',
        isSelected: false
      }
    ],
    tags: [''],
    payload: [] as PortfolioPayloadType[]
  },
  isNavOpen: false,
  counter: 0,
  authToken: false,
  user_interaction_portfolio: false,
  isUserLoggedOut: true,
  jwt: {} as JWTType,
  barbers: [
    {
      id: 0,
      user_id: 0,
      barberName: '',
      portfolio: {
        first_name: '',
        last_name: '',
        aboutMe: '',
        priceRange: '',
        location: '',
        facebook: {
          active: false,
          default: ''
        },
        whatsapp: {
          active: false,
          default: ''
        },
        telegram: {
          active: false,
          default: ''
        },
        telephone: '',
        profileImage: '',
        cardImages: [''],
        galleryImages: ['']
      },
      tags: ['']
    }
  ],
  current_user: {
    id: '' as string | number,
    email: '',
    status: 0,
    first_name: '',
    last_name: '',
    user_id: 0,
    account_id: '',
    about_me: '',
    fullname: '',
    picture: '',
    website_url: '',
    abilities: [''],
    settings: { not_implemented: '' },
    email_subscriptions: {
      terms_and_tonditions: ''
    }
  },
  subscribers: [] as SubscribersType[],
  portfolios_sessions: {} as PortfoliosSessionsType,
  current_account: {
    id: '' as string | number,
    type: '',
    attributes: {
      avatar: '',
      first_name: '',
      last_name: '',
      company: '',
      domain: '',
      booking_link: '',
      job_title: '',
      address: '',
      zip_code: 0,
      city: '',
      state: '',
      about_me: '',
      price_min: 0,
      price_max: 0,
      tags: '',
      abilities: '',
      invited_user_count: 0,
      fullname: '',
      account_id: '',
      status: 0,
      portfolio_count: 0,
      email: '',
      facebook: '',
      whatsapp: '',
      telegram: '',
      telephone: '',
      instagram: '',
      referral_code: ''
    }
  }
}))
// Define injection key.
// export const key: InjectionKey<Store<State>> = Symbol()
