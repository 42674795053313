<template>
  <div>
    <label :for="'checkbox_' + id" :class="['form-checkbox', klass]">
      <input
        :id="'checkbox_' + id"
        v-model="checked"
        :class="['form-field', klass]"
        type="checkbox"
        :tabindex="tabIndex"
        :required="isRequired"
        :disabled="isDisabled"
        :data-e2e="dataE2e"
      />
      <div v-html="label" />
    </label>
  </div>
</template>

<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    modelValue: {
      required: true,
      type: [Boolean, String],
    },
    id: { type: String, required: true },
    label: { type: String, required: true },
    klass: { type: Array, default: () => [] },
    tabIndex: { type: Number, required: true },
    isRequired: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    dataE2e: {
      type: String,
      required: false,
      default: '',
    },
  })

  const emit = defineEmits(['update:modelValue'])

  const checked = computed({
    get () {
      return props.modelValue
    },
    set (newChecked) {
      emit('update:modelValue', newChecked)
    },
  })
</script>
