<template>
  <div>
    <main>
      <h1>
        <span @click="updateSelectedSubTab(0)">Subscriber </span>
        <span v-if="selectedSubTab === 1" class="text-nowrap">
          > {{ currentAccount().attributes.fullname }}
        </span>
      </h1>

      <TabGroup :selected-index="selectedSubTab">
        <TabList v-show="false">
          <Tab />
          <Tab />
        </TabList>
        <TabPanels class="mt-5">
          <TabPanel>
            <data-table :items="subscribers()" :fields="fields">
              <template #cell(last_signin)="{ value }">
                <b>{{ lastSigning(value) }}</b>
              </template>
              <template #cell(created_at)="{ value }">
                <b>{{ createAt(value) }}</b>
              </template>
              <template #link="{ item }">
                <img
                  src="@/assets/btn-next.png"
                  srcset="@/assets/btn-next@2x.png 2x"
                  alt="View Profile"
                  @click="goToProfile(item.account_id)"
                />
              </template>
            </data-table>
          </TabPanel>
          <TabPanel>
            <SubscriberInformation
              :fullname="currentAccount().attributes.fullname"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  </div>
</template>

<script lang="ts">
  import { DataTable } from '@/components'
import { dateTimeShort } from '@/helpers'
import type { AccountType } from '@/types'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { computed } from 'vue'
import {
  Component,
  Emit,
  Inject,
  Vue,
  Watch,
  toNative,
} from 'vue-facing-decorator'
import { useSelectTabStore } from '../store/accounts/selectTabStore'
import SubscriberInformation from './SubscriberInformation.vue'

  @Component({
    components: {
      DataTable,
      SubscriberInformation,
      TabGroup,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
    },
  })
  class Subscribers extends Vue {
    @Inject
    currentAccount!: () => AccountType
    @Inject
    subscribers!: () => void

    createAt(date: Date) {
      return dateTimeShort(date)
    }

    selectedSubTab = computed(
      () => useSelectTabStore().getSelectedSubTab
    ) as unknown as number

    @Emit
    goToProfile(account_id: string) {
      return { account_id }
    }

    @Emit
    updateSelectedSubTab(index: number) {
      this.$router.push({ query: undefined })
      return index
    }

    @Watch('$route.name', { immediate: true, deep: true })
    updatePageTitle(name: string) {
      if (name === 'admin') {
        document.title = `Admin - Subscribers`
      }
    }

    lastSigning(date: Date) {
      if (date) {
        return dateTimeShort(date)
      }
    }

    fields = [
      { key: 'account_id', label: 'ID', sortable: true },
      { key: 'first_name', label: 'First name', sortable: true  },
      { key: 'last_name', label: 'Last name', sortable: true  },
      { key: 'created_at', label: 'Registered', sortable: true  },
      { key: 'last_signin', label: 'Last sign in', sortable: true },
      { key: 'images', label: 'Images' },
      { key: 'subscription', label: 'Subscription', sortable: true },
      { key: 'referred', label: 'Referred', sortable: true  },
      { key: 'email', label: 'Email', sortable: true  },
    ]
  }
  export default toNative(Subscribers)
</script>

<style scoped></style>
