import { defineStore } from 'pinia';

export const useConsumerStore = defineStore('consumerStore', {
  state: () => ({
    uuid: '',
    socketId: '',
    action: '',
    connection_id: '',
    connection_identifier: '',
    account_id: '',
    userOnline: [] as { connection_id?: string, connection_identifier: string, account_id: string, status: string }[]
  }),
  getters: {
    isSubscribed: (state) => {
      return state.uuid !== '' && state.action !== '' && state.connection_id !== '';
    },
    isUserOnline: (state) => {
      return state.userOnline.length > 0;
    },
    isUserOnlineByConnectionId: (state) => (connectionId: string) => {
      return state.userOnline.find(user => user.connection_id === connectionId);
    },
    getConnectionID: (state) => {
      return state.connection_id || state.connection_identifier;
    }

  },
  actions: {
    saveSubscribedData(data: any) {
      this.uuid = data['uuid'];
      this.socketId = data['socket_id'];
      this.action = data['action'];
      this.connection_id = data['connection_id'];
      this.account_id = data['account_id'];
      this.connection_identifier = data['connection_identifier'];
    },

    clearSubscribedData() {
      this.uuid = '';
      this.socketId = '';
      this.action = '';
      this.connection_id = '';
      this.connection_identifier = '';
      this.userOnline = [];
    },
    findCurrentUserOnline(accountId: string) {
      return this.userOnline.find(user => user.account_id === accountId);
    },
    updateUserOnline(updatedUsers: { connection_id: string, account_id: string, status: string, connection_identifier: string }[] | { connection_id: string, account_id: string, status: string, connection_identifier: string }) {
      const usersArray = Array.isArray(updatedUsers) ? updatedUsers : [updatedUsers];
      usersArray.forEach(updatedUser => {
        const existingUserIndex = this.userOnline.findIndex(user => user.connection_identifier === updatedUser.connection_identifier);
        console.log('existingUserIndex', updatedUser, existingUserIndex);
        if (existingUserIndex !== -1) {
          this.userOnline[existingUserIndex] = updatedUser; // Update existing user
        } else {
          this.userOnline.push(updatedUser); // Add new user
        }
      });
    },
    removeUserByConnectionId(connectionId: string) {
      const userIndex = this.userOnline.findIndex(user => user.connection_id === connectionId);
      if (userIndex !== -1) {
        this.userOnline.splice(userIndex, 1); // Remove the user if found
      }
    },
    removeAllUsersByConnectionId(connectionId: string) {
      console.log('connectionId', connectionId, this.userOnline.filter(user => user.connection_id !== connectionId))
      this.userOnline = this.userOnline.filter(user => user.connection_id !== connectionId);
    }
  },
  persist: false,
  // persist: {
  //   storage: sessionStorage
  // }
});
