import ErrorMessage from '@/ErrorMessage'
import {
  TokenDataService as Token,
  UserLoginDataService as User,
  type UserLoginRequest,
  UserLogoutDataService,
  UserRegisterDataService as UserRegister,
  type UserRegisterRequest
} from '@/services'
import { useErrorStore } from '@/store/errorStore'
import { state as initialState } from '@/store/state'
import type { ApiErrorResponse, ApiResponse, UserType } from '@/types/index'
import { useResetStore } from '@/utils/useResetStore'
import Bugsnag from '@bugsnag/js'
import { pick } from 'lodash'
import { defineStore } from 'pinia'
import { ActionTypes } from '../action-types'
import { useToastStore } from '../toastStore'
const state = pick(initialState, [
  'jwt',
  'status',
  'statusText',
  'isRequestLoading',
  'isUserLoggedOut',
  'message',
  'errors',
  'authToken',
  'current_user',
  'isLoading',
  'slideshow_data'
])

export const useAuthTokenStore = defineStore('authTokenStore', {
  state: () => ({ ...state }),
  getters: {
    currentUser({ current_user }) {
      return current_user
    },
    currentJWT({ jwt }) {
      return jwt
    },
    isTokenExpired({ jwt, authToken }): boolean {
      if (jwt.expire_at === undefined) return true
      const now = new Date()
      const expire_time = new Date(Number(jwt.expire_at) * 1000 - 60000)
      if (expire_time <= now) {
        Object.assign(initialState)
        this.isUserLoggedOut = authToken
        this.authToken = !authToken
      }

      return expire_time <= now
    }
  },
  actions: {
    updateJWT(jwt: { access_token: string; refresh_token: string; expire_at: string | number }) {
      this.jwt = jwt
    },
    updateAuthToken(authToken: boolean) {
      this.authToken = authToken
    },
    updateCurrentUser(current_user: UserType) {
      this.current_user = current_user
    },
    async [ActionTypes.USER_REFERRAL_CODE_VERIFICATION]({
      data
    }: {
      data: Record<string, string>
    }) {
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const user = UserRegister.referralCodeVerificarion(data)

        resolve(user)
      })

      try {
        const { status, data, statusText } = await promise
        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.'
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.USER_REFERRAL_CODE_VERIFICATION, error)
            Bugsnag.notify(errorMessage)
          }
          useErrorStore().UPDATE_ERROR(error)
        }
        this.isRequestLoading = false
      }
    },
    async [ActionTypes.USER_LOGIN]({ login_data }: { login_data: UserLoginRequest }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const user = User.login(login_data)
        resolve(user)
      })

      try {
        const { status, data, statusText
        } = await promise
        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
        this.updateAuthToken(true)
        this.isUserLoggedOut = !this.authToken
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.'
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.USER_LOGIN, error)
            Bugsnag.notify(errorMessage)
          }
          useErrorStore().UPDATE_ERROR(error)
        }
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.USER_SIGNUP]({ signup_data }: { signup_data: UserRegisterRequest }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const user = UserRegister.register(signup_data)

        resolve(user)
      })

      try {
        const { status, data, statusText } = await promise
        this.message = data.message
        this.errors = {} as ApiErrorResponse
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.'
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.USER_SIGNUP, error)
            Bugsnag.notify(errorMessage)
          }
          useErrorStore().UPDATE_ERROR(error)
        }
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.TOKEN_RENEWER]() {
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const token = Token.renew()

        resolve(token)
      })

      try {
        const { data, status, statusText } = await promise

        this.message = data.message
        this.errors = {} as ApiErrorResponse
        this.status = Number(status)
        this.statusText = statusText
        this.isUserLoggedOut = !this.authToken
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.'
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.TOKEN_RENEWER, error)
            Bugsnag.notify(errorMessage)
          }
          useErrorStore().UPDATE_ERROR(error)
          useResetStore().all()
          useResetStore().authTokenStore()
        }
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
        }
      }
    },
    async [ActionTypes.USER_LOGOUT]() {
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const userLogout = UserLogoutDataService.logout()

        resolve(userLogout)
      })

      try {
        const { data } = await promise
        useToastStore().SHOW_NOTIFICATION(data.message, 'success', true, false)
      } catch (error: any) {
        if (error.response?.status === 500) {
          this.message = 'Oops! Something went wrong on our end. Please try again shortly.'
        } else {
          if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
            const errorMessage = new ErrorMessage(ActionTypes.USER_LOGOUT, error)
            Bugsnag.notify(errorMessage)
          }
          useErrorStore().UPDATE_ERROR(error)
        }
      } finally {
        useResetStore().all()
        this.isRequestLoading = false
      }
    }
  },
  persist: {
    storage: sessionStorage
  }
})
