<template>
  <div
    v-if="false"
    class="payment-and-transactions"
  >
    <h1>Payment and Transactions</h1>
    <form
      v-if="stripeLoaded"
      @submit.prevent="handleSubmit"
    >
      <StripeElements
        ref="elementsComponent"
        :stripe-key="stripeKey"
        :instance-options="stripeOptions"
        :elements-options="elementsOptions"
      >
        <StripeElement
          ref="paymentComponent"
          type="payment"
          :options="paymentElementOptions"
        />
      </StripeElements>

      <IButton
        :action="'success'"
        :tab-index="-1"
        class="btn btn-primary my-4"
        :data-e2e="'add-stripe-card'"
        type="submit"
      >
        Submit
        <span :class="isRequestLoading && 'loader'" />
      </IButton>
    </form>

    <h1>Transactions</h1>
    <data-table
      :items="transactions"
      :fields="fields"
    >
      <template #cell(status)="{ value }">
        <span :class="getStatusClass(value)">{{ value }}</span>
      </template>
    </data-table>
  </div>
</template>

<script setup lang="ts">
import {
  DataTable,
  Button as IButton,
} from '@/components'

// import { loadStripe } from "@stripe/stripe-js"
import { onBeforeMount, ref } from "vue"
// import { StripeElement, StripeElements } from "vue-stripe-js"

import { useAccountStore } from '@/store/accounts'
import type {
  StripeElementsOptionsMode,
  StripePaymentElementOptions,
} from "@stripe/stripe-js"

const account_id = useAccountStore().getCurrentAccount.account_id

// Define your stripe key and options
const stripeKey = import.meta.env.STRIPE_PUBLISHABLE_KEY // use your publishable key
const stripeOptions = ref({
  // https://stripe.com/docs/js/initializing#init_stripe_js-options
  apiVersion: '2020-08-27',
  locale: 'auto',
  currency: 'eur',
})
const elementsOptions = ref<StripeElementsOptionsMode>({
  // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  mode: "subscription",
  amount: 599,
  currency: 'eur',
  appearance: {
    theme: "flat",
  },
  clientSecret: account_id,
})
const paymentElementOptions = ref<StripePaymentElementOptions>({
  business: {
    name: "Zlonc Limited",
  },
  // https://docs.stripe.com/js/elements_object/create_payment_element#payment_element_create-options
})
const stripeLoaded = ref(false)
const clientSecret = ref(account_id)

const isRequestLoading = ref(false)
// Define component refs
const elementsComponent = ref()
const paymentComponent = ref()

onBeforeMount(() => {
  // loadStripe(stripeKey).then(() => {
  //   stripeLoaded.value = true

  //   // Good place to call your backend to create PaymentIntent
  //   // Skipping to the point when you got client_secret

  //   // clientSecret.value = client_secret
  // })
})

async function handleSubmit() {
  // Confirm the PaymentIntent using the details collected by the Payment Element
  const stripeInstance = elementsComponent.value?.instance
  const elements = elementsComponent.value?.elements

  if (stripeInstance) {
    const { error } = await stripeInstance.confirmPayment({
      elements,
      clientSecret: clientSecret.value,
      confirmParams: {
        return_url: import.meta.env.VITE_STRIPE_RETURN_URL,
      },
    })

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      console.log(error)
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }
}

function getStatusClass(status: string) {
  if (status === 'Completed') return 'text-success'
  if (status === 'Pending') return 'text-warning'
  return 'text-danger'
}

const fields = [
  { key: 'date', label: 'Date' },
  { key: 'amount', label: 'Amount' },
  { key: 'status', label: 'Status', sortable: true },
]

const transactions = ref([
  { id: 1, date: '2023-10-01', amount: '$10.99', status: 'Completed' },
  { id: 2, date: '2023-10-02', amount: '$15.49', status: 'Pending' },
  { id: 3, date: '2023-10-03', amount: '$20.00', status: 'Failed' },
])
</script>

<style scoped>
.payment-and-transactions {
  /* Add your component styles here */
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transactions-table th,
.transactions-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.transactions-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.text-success {
  color: green;
}

.text-warning {
  color: rgb(255, 149, 0);
}

.text-danger {
  color: red;
}
</style>
