<template>
  <main>
    <single-upload
      :url_link="currentAccount().attributes.avatar"
      :img_title="'Choose image'"
      :tab-index="tabIndex('avatar')"
      @is-converting="onConverting"
    />

    <form
      class="form"
      novalidate="true"
      @submit.prevent="handleAccount"
    >
      <h5 class="mt-5 mb-1 text-left">
        Company Details
      </h5>

      <div class="my-3">
        <IInput
          :id="'company'"
          v-model.trim="currentAccount().attributes.company"
          :label="'Company Name'"
          :placeholder="'Company Name'"
          :help-text="''"
          :input-type="'text'"
          :tab-index="tabIndex('company')"
          :is-required="true"
          :klass="[
            getError && getError.input_name == 'company' && getError.status,
          ]"
          :data-e2e="'company-input'"
        />
      </div>

      <div class="my-3">
        <mapbox-address-autofill
          :access-token="accessToken"
          language="de"
          popover-options="{offset: 30}"
        >
          <IInput
            :id="'address'"
            v-model.trim="currentAccount().attributes.address"
            :label="'Street'"
            :input-type="'text'"
            :placeholder="'Street'"
            :help-text="''"
            :tab-index="tabIndex('address')"
            :is-required="true"
            :autocomplete="'street-address'"
            :klass="[
              getError && getError.input_name == 'address' && getError.status,
            ]"
            :data-e2e="'address-input'"
          />
        </mapbox-address-autofill>
      </div>

      <div class="my-3">
        <IInput
          :id="'zip_code'"
          v-model.trim="currentAccount().attributes.zip_code"
          :label="'ZIP'"
          :input-type="'number'"
          :placeholder="'ZIP'"
          :help-text="''"
          :tab-index="tabIndex('zip_code')"
          :is-required="true"
          :autocomplete="'postal-code'"
          :klass="[
            getError && getError.input_name == 'zip_code' && getError.status,
          ]"
          :data-e2e="'zip-code-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'city'"
          v-model.trim="currentAccount().attributes.city"
          :label="'City'"
          :input-type="'text'"
          :placeholder="'City'"
          :help-text="''"
          :tab-index="tabIndex('city')"
          :is-required="true"
          :klass="[
            getError && getError.input_name == 'city' && getError.status,
          ]"
          :autocomplete="'address-level2'"
          :data-e2e="'city-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'state'"
          v-model.trim="currentAccount().attributes.state"
          :label="'State'"
          :input-type="'text'"
          :placeholder="'State'"
          :help-text="''"
          :tab-index="tabIndex('state')"
          :autocomplete="'address-level1'"
          :data-e2e="'state-input'"
        />
      </div>

      <h5 class="mt-5 mb-1 text-left">
        Website & Social Media
      </h5>

      <div class="my-3">
        <IInput
          :id="'domain'"
          v-model.trim="currentAccount().attributes.domain"
          :label="'Website Link'"
          :input-type="'text'"
          :placeholder="'https://your-domain.com'"
          :help-text="''"
          :tab-index="tabIndex('domain')"
          :data-e2e="'domain-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'booking_link'"
          v-model.trim="currentAccount().attributes.booking_link"
          :label="'Order Link'"
          :input-type="'text'"
          :placeholder="'https://your-domain.com/order'"
          :help-text="''"
          :tab-index="tabIndex('booking_link')"
          :data-e2e="'booking-link-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'facebook'"
          v-model.trim="currentAccount().attributes.facebook"
          :label="'Facebook'"
          :input-type="'text'"
          :placeholder="'Facebook username'"
          :help-text="''"
          :tab-index="tabIndex('facebook')"
          :data-e2e="'facebook-input'"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'instagram'"
          v-model.trim="currentAccount().attributes.instagram"
          :label="'Instagram'"
          :input-type="'text'"
          :placeholder="'Instagram username (without @)'"
          :help-text="''"
          :tab-index="tabIndex('instagram')"
          :data-e2e="'instagram-input'"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'telegram'"
          v-model.trim="currentAccount().attributes.telegram"
          :label="'Telegram'"
          :input-type="'text'"
          :placeholder="'Telegram username (without @)'"
          :help-text="''"
          :tab-index="tabIndex('telegram')"
          :data-e2e="'telegram-input'"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'telephone'"
          v-model.trim="currentAccount().attributes.telephone"
          :label="'Phone'"
          :input-type="'text'"
          :placeholder="'e.g. +49 123456789'"
          :help-text="''"
          :tab-index="tabIndex('telephone')"
          :data-e2e="'telephone-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'whatsapp'"
          v-model.trim="currentAccount().attributes.whatsapp"
          :label="'Whatsapp'"
          :input-type="'text'"
          :placeholder="'e.g. +49 123456789'"
          :help-text="''"
          :tab-index="tabIndex('whatsapp')"
          :data-e2e="'whatsapp-input'"
        />
      </div>

      <h5 class="mt-5 mb-1 text-left">
        User Details <small class="font-weight-light">(only visible to you)</small>
      </h5>

      <div class="my-3">
        <IInput
          :id="'first_name'"
          v-model.trim="currentAccount().attributes.first_name"
          :label="'First Name'"
          :input-type="'text'"
          :placeholder="'First Name'"
          :help-text="''"
          :tab-index="tabIndex('first_name')"
          :data-e2e="'first-name-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'last_name'"
          v-model.trim="currentAccount().attributes.last_name"
          :label="'Last Name'"
          :input-type="'text'"
          :placeholder="'Last Name'"
          :help-text="''"
          :tab-index="tabIndex('last_name')"
          :data-e2e="'last-name-input'"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'job_title'"
          v-model.trim="currentAccount().attributes.job_title"
          :label="'Position'"
          :input-type="'text'"
          :placeholder="'e.g. CEO, Founder'"
          :help-text="''"
          :tab-index="tabIndex('job_title')"
          :data-e2e="'job-title-input'"
        />
      </div>

      <h5 class="mt-5 mb-1 text-left">
        Your Profile Page <small class="font-weight-light">(only online on ypony.com)</small>
      </h5>

      <div class="my-3">
        <ITextarea
          :id="'aboutyou'"
          v-model.trim="currentAccount().attributes.about_me"
          :rows="5"
          :tab-index="tabIndex('about_me')"
          :placeholder="'Introduce yourself on your ypony.com profile page.'"
          :data-e2e="'about-me-input'"
        />
      </div>

      <h5 class="mt-5 mb-1 text-left">
        Your pricing range
      </h5>

      <div class="pricing-range d-flex flex-row column-gap-3">
        <div class="pricing-range--input-wrapper">
          <IInput
            :id="'price_min'"
            v-model="currentAccount().attributes.price_min"
            :label="'Minimum price'"
            :input-type="'number'"
            :placeholder="'e.g. 10'"
            :tab-index="tabIndex('price_min')"
            :data-e2e="'price-min-input'"
          />
        </div>

        <div class="pricing-range--input-wrapper">
          <IInput
            :id="'price_min'"
            v-model="currentAccount().attributes.price_max"
            :label="'Maximum price'"
            :input-type="'number'"
            :placeholder="'e.g. 100'"
            :tab-index="tabIndex('price_max')"
            :data-e2e="'price-max-input'"
          />
        </div>
      </div>

      <h5 class="mt-5 mb-1 text-left">
        Tags <small class="font-weight-light">(User will find you by these #Tags on ypony.com)</small>
      </h5>

      <ul class="tag-cloud text-left">
        <li
          v-for="(tag, index) in currentAccount().attributes.tags"
          :key="index"
          :data-e2e="tag.replace('#', '') + '-tag'"
        >
          {{ tag }}
        </li>
      </ul>

      <div class="align-button">
        <IButton
          :is-disabled="isConverting"
          :action="'success'"
          class="btn btn-primary mb-2 mt-5 my-md-5 mx-1"
          :tab-index="20"
          :is-request-loading="isConverting"
          :data-e2e="'submit-button'"
        >
          Save changes
        </IButton>

        <router-link
          v-if="currentAccount().attributes.company"
          :to="{
            query: {
              account_id: currentAccount().attributes.account_id,
            },
            path: `/${createSlug(currentAccount().attributes.company)}/me`,
          }"
          class="btn btn-primary mt-2 mb-5 my-md-5 mx-1"
        >
          View online profile
        </router-link>
      </div>
    </form>
  </main>
</template>

<script lang="ts">
  import { type AccountType } from '@/types'
import { cloneDeep, isEqual } from 'lodash'
import { Component, Inject, toNative, Vue } from 'vue-facing-decorator'

  import {
  Button as IButton,
  Input as IInput,
  Textarea as ITextarea,
  singleUpload,
} from '@/components'
import { createSlug } from '@/helpers'
import { useErrorStore } from '@/store/errorStore'
import { useToastStore } from '@/store/toastStore'
import { MapboxAddressAutofill } from '@mapbox/search-js-web'
import { watch } from 'vue'

  @Component({
    components: { IInput, IButton, ITextarea, singleUpload },
    emits: ['update:handleAccount'],
  })
  class PublicDetails extends Vue {
    @Inject
    currentAccount!: () => AccountType

    isConverting = false
    accessToken = import.meta.env.VITE_MAPBOX_APIKEY
    restrictedCountry = 'de'
    errorStore = useErrorStore()
    canSubmitted = false

    get getError() {
      return this.errorStore.getError
    }

    tabIndex(key: string) {
      const accountKeys = Object.keys(this.currentAccount().attributes)

      return accountKeys.indexOf(key)
    }
    createSlug(company: string) {
      return createSlug(company)
    }
    onConverting(value: boolean) {
      this.isConverting = value
    }

    handleAccount() {
      if (this.canSubmitted) {
        this.$emit('update:handleAccount', '', 'public_account', true)
        this.canSubmitted = false
      } else {
        useToastStore().SHOW_NOTIFICATION('No information has been updated', 'warning', true, false)
      }
    }

    mounted() {
      new MapboxAddressAutofill()

      watch(() => cloneDeep(this.currentAccount().attributes), (newVal, oldVal) => {
        const isValuesEmpty = Object.values(oldVal || {}).some(value => value !== '' && value !== 0)
        if (isValuesEmpty && !isEqual(newVal, oldVal)) {
          this.canSubmitted = true
        }
      }, { deep: true, immediate: true })
    }
  }
  export default toNative(PublicDetails)
</script>

<style lang="scss">
  .about {
    .profile-gallery h5 {
      max-width: 68vw;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .align-button {
    display: flex;
    gap: 0.25em 0.75em;
    text-wrap: nowrap;
    flex-flow: wrap;
    justify-content: center;
    flex-direction: row;
    margin: 0;
  }

  .loader {
    margin-left: 8px;
    margin-top: -1px;
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-bottom-color: #000;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  input[type='checkbox']:disabled {
    background: gray !important;
    border: 1px solid #d4d4d5;
  }
  input[required] {
    border-color: initial;
  }
  input.error {
    border-color: red;
  }
</style>
