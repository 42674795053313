<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <template v-for="{ key, label, sortable } in fields" :key="key">
            <th v-if="sortable" class="sortable" @click="setSort(key)">
              {{ label }}
              <template v-if="sortBy === key">
                <span v-if="sortDesc === true">↑</span>
                <span v-else-if="sortDesc === false">↓</span>
              </template>
            </th>
            <th v-else>
              {{ label }}
            </th>
          </template>
          <th v-if="$slots['portfolio-images']" />
        </tr>
      </thead>

      <tbody>
        <template v-for="item in paginatedItems" :key="item.account_id">
          <tr>
            <td v-for="{ key } in fields" :key="key">
              <slot :name="`cell(${key})`" :value="item[key]" :item="item">
                {{ item[key] }}
              </slot>
            </td>
            <td>
              <slot name="link" :item="item" />
            </td>
          </tr>
          <tr v-if="$slots['portfolio-images']">
            <td colspan="100">
              <slot name="portfolio-images" :item="item" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <div class="text-right">
    <button class="btn btn-icon" :disabled="pagination.currentPage <= 1" @click="pagination.currentPage--">
      ←
    </button>
    Page {{ pagination.currentPage }} of {{ pagination.totalPages }}
    <button class="btn btn-icon" :disabled="pagination.currentPage >= pagination.totalPages"
      @click="pagination.currentPage++">
      →
    </button>
  </div>
</template>

<script setup lang="ts">
import { eventBus } from '@/utils/eventBus';
import { sort } from 'fast-sort';
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';

interface Item {
  [key: string]: any
}

interface Field {
  key: string
  label: string
  sortable?: boolean
}

interface Props {
  items: Item[]
  fields: Field[]
}

interface Pagination {
  currentPage: number
  perPage: number
  totalPages: number
}

const props = defineProps<Props>()

const pagination: Pagination = reactive({
  currentPage: 1,
  perPage: 20,
  totalPages: computed(() =>
    Math.ceil(items.length / pagination.perPage)
  ),
})

const items = reactive<Item[]>(props.items)
const listeners: Record<string, any> = {}; // Store event listeners for cleanup

const sortBy = ref<string | null>(null)
const sortDesc = ref<boolean | null>(null)

const sortedItems = computed<Item[]>(() => {
  if (sortDesc.value === null) return items

  if (sortDesc.value) {
    return sort(items).desc(item => item[sortBy.value as string])
  }
  return sort(items).asc(item => item[sortBy.value as string])
})

const setSort = (key: string) => {
  if (sortBy.value === key) {
    if (sortDesc.value === true) sortDesc.value = null
    else if (sortDesc.value === false) sortDesc.value = true
    else sortDesc.value = false
  } else {
    sortBy.value = key
    sortDesc.value = false
  }
}

const paginatedItems = computed<Item[]>(() => {
  const { currentPage, perPage } = pagination
  const start = (currentPage - 1) * perPage
  const stop = start + perPage

  return sortedItems.value.slice(start, stop)
})

watch(
  () => pagination.totalPages,
  () => {
    if (pagination.currentPage > pagination.totalPages) {
      pagination.currentPage = pagination.totalPages
    }
  }
)

// Add event listeners for each row
onMounted(() => {
  items.forEach((item) => {
    const eventName = `update-row-${item.account_id}`;
    const listener = (updatedRow: any) => {
      const rowIndex = items.findIndex((row) => row.account_id === updatedRow.account_id);

      if (rowIndex !== -1) {
        console.log('rowIndex', updatedRow, eventName);
        // Update specific columns
        items[rowIndex].status = updatedRow.status;
        items[rowIndex].runtime_today = updatedRow.runtime_today;
        items[rowIndex].runtime_total = updatedRow.runtime_total;
        items[rowIndex].slideshow_online_count = updatedRow.slideshow_online_count;
      }
    };

    eventBus.on(eventName, listener);
    listeners[eventName] = listener; // Store the listener for cleanup
  });
});

// Cleanup event listeners
onBeforeUnmount(() => {
  Object.keys(listeners).forEach((eventName) => {
    eventBus.off(eventName, listeners[eventName]);
  });
});
</script>

<style>
.table-responsive {
  border: 1px solid black;
}

.table {
  width: 100%;
}

.table>thead>tr {
  border-bottom: 1px solid black;
}

.table>tbody>tr:nth-child(odd) {
  /* background-color: rgba(0, 0, 0, 0.2); */
}

th.sortable {
  cursor: pointer;
}
</style>
