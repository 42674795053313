<template>
  <div>
    <main>
      <h1>
        <span @click="updateSelectedSubTab(0)">Analytics </span>
        <span v-if="selectedSubTab === 1" class="text-nowrap">
          > {{ currentAccount()?.attributes?.fullname || fullnames }}
        </span>
      </h1>
      <TabGroup :selected-index="selectedSubTab">
        <TabPanels class="mt-5">
          <TabPanel>
            <data-table :items="analytics()" :fields="fields">
              <template #cell(status)="{ value, item }">
                <span class="icon-container">
                  <div v-if="item.slideshow_online_count !== 0" :class="getStatusClass(value)">
                    {{ item.slideshow_online_count }}
                  </div>
                  <div v-else :class="getStatusClass(value)"></div>
                </span>
              </template>
              <template #link="{ item }">
                <img src="@/assets/btn-next.png" srcset="@/assets/btn-next@2x.png 2x" alt="View Profile"
                  @click.prevent="goToProfile(item.account_id)" />
              </template>
            </data-table>
          </TabPanel>
          <TabPanel>
            <AnalyticProfileDetails :analytics="analytics()" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  </div>
</template>

<script lang="ts">
import { DataTable } from '@/components'
import { dateShort, weekDayShort } from '@/helpers'
import type { AccountType } from '@/types'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { computed } from 'vue'
import {
  Component,
  Emit,
  Inject,
  Vue,
  Watch,
  toNative,
} from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { useSelectTabStore } from '../store/accounts/selectTabStore'
import AnalyticProfileDetails from './AnalyticProfileDetails.vue'

@Component({
  components: {
    DataTable,
    AnalyticProfileDetails,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
})
class Subscribers extends Vue {
  @Inject
  currentAccount!: () => AccountType
  @Inject
  analytics!:  () => any[]
  router = useRouter()
  route = useRoute()

  get fullnames() {
    const accountId = this.route.query.account_id as string
    return this.analytics().find((analytic: any) => analytic.account_id === accountId).fullname || ''
  }


  createAt(date: Date) {
    return dateShort(date)
  }

  selectedSubTab = computed(
    () => useSelectTabStore().getSelectedSubTab
  ) as unknown as number

  @Emit
  goToProfile(account_id: string) {
    return { account_id }
  }

  @Emit
  updateSelectedSubTab(index: number) {
    this.router.push({ query: undefined })
    return index
  }

  lastSigning(date: Date) {
    if (date) {
      return weekDayShort(date)
    }
  }

  @Watch('$route.name', { immediate: true, deep: true })
  updatePageTitle(name: string) {
    if (name === 'admin') {
      document.title = `Admin - Analytics`
    }
  }

  fields = [
    { key: 'status', label: 'status' },
    { key: 'first_name', label: 'First name' },
    { key: 'last_name', label: 'Last name' },
    { key: 'city', label: 'Location' },
    {
      key: 'runtime_today',
      label: 'Runtime today',
      sortable: true,
    },
    { key: 'runtime_total', label: 'Runtime total', sortable: true },
  ]

  getStatusClass(value: string) {
    return value === 'active'
      ? 'status-active'
      : value === 'connected'
      ? 'status-connected'
      : 'status-inactive';
  }
}
export default toNative(Subscribers)
</script>

<style scoped>
.icon-container {
  width: 10px;
  height: 10px;
}

.status-online {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
  color: white;
  text-align: center;
}

.status-offline {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgb(240, 14, 14);
  border: 2px solid rgb(240, 14, 14);
}

.status-active {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
  color: white;
  text-align: center;
}

.status-connected {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: orange;
  border: 2px solid orange;
  color: white;
  text-align: center;
}

.status-inactive {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: red;
  border: 2px solid red;
  color: white;
  text-align: center;
}

.circle-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: white;
}

.circle-badge.active {
  background-color: green;
}

.circle-badge.inactive {
  background-color: red;
}

.circle-badge.pending {
  background-color: orange;
}
</style>
